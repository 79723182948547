<div class="bg-dash">

    <div class="page-padding">
        <div class="page-header-custom">
            <h1 class="page-title">{{'dashboard'|translate}}<!-- | <span>Gestão</span>--></h1>
            <!--<a href="javascript:void('');" class="btn btn-gray-fill">
                <svg-icon class="right" src="assets/svg/downloads.svg"></svg-icon>
                Improtar Excel
            </a>-->
            <!--            <a href="javascript:void('');" class="btn btn-gray-fill">-->
            <!--                <svg-icon class="right" src="assets/svg/downloads.svg"></svg-icon>-->
            <!--                {{'exportarexcel'|translate}}-->
            <!--            </a>-->
        </div>
        <div class="page-filter">

            <div class="input-material">
                <select id="campaign" class="form-control" name="type" [(ngModel)]="filter.evaluation">
                    <option [ngValue]="undefined">Todos</option>
                    <option *ngFor="let e of evaluations" [ngValue]="e.id">{{e.name}}</option>
                </select>
                <label for="campaign" class="mb-0 mr-3">{{'campanha'|translate}}</label>
            </div>
            <div class="input-material">
                <select id="unity" class="form-control" name="type" [(ngModel)]="filter.unity">
                    <option [ngValue]="undefined">Todos</option>
                    <option *ngFor="let e of allUnities" [ngValue]="e.id">{{e.name}}</option>
                </select>
                <label for="unity" class="mb-0 mr-3">{{'unidade' | translate}}</label>
            </div>

            <div class="input-material">
                <input id="name" [(ngModel)]="filter.area" class="form-control" type="text" required/>
                <label for="name">
                    <span>{{'area'|translate}}</span>
                </label>
            </div>

            <app-date-picker (selectDate)="selectedDate($event)" [start]="this.filter.start"
                             [end]="this.filter.end"></app-date-picker>

            <a (click)="onFilter()" class="btn btn-primary btn-lg text-white">{{'buscar'|translate}}</a>
        </div>
        <div class="dashboard">
            <div class="row">
                <div class="col-md-6">
                    <h3>{{'adesao' |translate}}</h3>
                    <div class="dash-card">
                        <ng-container *ngIf="!isNullOrUndefined(this.adhesionDashboard)">
                            <google-chart style="width: 100%;" [type]="'ColumnChart'" [options]="dashboardOptions"
                                          [columns]="adhesionDashboardColumn"
                                          [data]="adhesionDashboardGraph"></google-chart>
                        </ng-container>
                        <div *ngIf="isNullOrUndefined(this.adhesionDashboard)">
                            <p class="text-center mb-0">{{'notFound' | translate }}</p>
                        </div>
                    </div>
                    <h3>{{'mediadaAvaliacao' | translate}}</h3>
                    <div class="dash-card">
                        <ng-container *ngIf="!isNullOrUndefined(this.evaluationMedia)">
                            <google-chart style="width: 100%;" [type]="'LineChart'"
                                          [columns]="evaluationMediaColumn"
                                          [data]="evaluationMediaGraph"></google-chart>
                        </ng-container>
                        <div *ngIf="isNullOrUndefined(this.evaluationMedia)">
                            <p class="text-center mb-0">{{'notFound' | translate }}</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <h3>Ranking de {{user.profile | translate}}</h3>
                    <div class="dash-card">
                        <div class="manager-row" *ngFor="let x of managerRankList.results |
                paginate: {itemsPerPage: 10,
                currentPage: managerRankList.currentPage,
                totalItems: managerRankList.totalCount}; let i = index">
                            <p>{{(managerRankList.currentPage - 1) * 10 + i + 1}}º</p>
                            <span>{{x.name}}</span>
                            <b>{{x.percentage}}%</b>
                        </div>
                        <div *ngIf="isNullOrUndefined(this.managerRankList)">
                            <p class="text-center mb-0">{{'notFound' | translate }}</p>
                        </div>
                        <div class="text-right">
                            <pagination-controls
                                    [previousLabel]="labels.previousLabel"
                                    [nextLabel]="labels.nextLabel"
                                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                                    (pageChange)="pageChanged($event)"></pagination-controls>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="this.user.profile === 'admin'">
                    <div class="col-md-7">
                        <h3>{{'competencia' |translate }}</h3>
                        <div class="dash-card">
                            <div *ngFor="let x of competenceDashboard" class="question_card finished"
                                 style="cursor:initial">
                                <div class="question_card_top">
                                    <div class="row" style="row-gap: 15px">
                                        <div class="col">
                                            <div class="d-flex align-items-center">
                                                <span>{{x.name}}</span>
                                                <a data-container="body" data-toggle="popover" data-placement="top"
                                                   data-trigger="hover" *ngIf="!isNullOrUndefined(x.informative)"
                                                   [attr.data-content]="x.informative">
                                                    <svg-icon src="assets/svg/info.svg"></svg-icon>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-auto row no-gutters align-items-center" style="gap: 15px">
                                            <div class="row no-gutters flex-grow-1">
                                                <a href="javascript:void(0)">
                                                    <svg-icon *ngFor="let i of [].constructor(x.average)"
                                                              class="is-cursor"
                                                              src="assets/svg/star-active.svg"></svg-icon>
                                                </a>
                                            </div>
                                            <span>{{x.percentage}}%</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="isNullOrUndefined(this.competenceDashboard)">
                                <p class="text-center mb-0">{{'notFound' | translate }}</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <h3>{{'statusDaAvaliacao'|translate}}</h3>
                        <div class="dash-card">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="status pending">
                                        {{userEvaluation.pending}}
                                    </div>
                                    <h4>{{'pendente'|translate}}</h4>
                                </div>
                                <div class="col-md-4">
                                    <div class="status notfinished">
                                        {{userEvaluation.doing}}
                                    </div>
                                    <h4>{{'naofinalizada'|translate}}</h4>
                                </div>
                                <div class="col-md-4">
                                    <div class="status finished">
                                        {{userEvaluation.done}}
                                    </div>
                                    <h4>{{'finalizada'|translate}}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <h3>Feedbacks</h3>
                        <div class="dash-card">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="dash-card bgDash">
                                        <h4>{{'unidade' | translate}}</h4>
                                        <ng-container *ngIf="!isNullOrUndefined(this.unityDashboard.graph)">
                                            <google-chart style="width: 100%;" [type]="'ColumnChart'"
                                                          [options]="dashboardOptions" [columns]="unityDashboardColumn"
                                                          [data]="unityDashboardGraph"></google-chart>
                                            <div class="p-3">
                                                <div class="row no-wrap legend-row top justify-content-between">
                                                    <p>Nome Unidade</p>
                                                    <p>Total</p>
                                                </div>
                                                <ng-container *ngFor="let x of unityDashboard.dashboard">
                                                    <div class="row no-wrap legend-row align-items-center justify-content-between">
                                                        <div class="d-flex align-items-center">
                                                            <div class="square"
                                                                 [ngStyle]="{'background-color': x.color}"></div>
                                                            <p>{{x.name}}</p>
                                                        </div>
                                                        <p>{{x.count}}</p>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                        <div *ngIf="isNullOrUndefined(this.unityDashboard.graph)">
                                            <p class="text-center mb-0">{{'notFound' | translate }}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="dash-card bgDash">
                                        <h4>{{'area'|translate}}</h4>
                                        <ng-container *ngIf="!isNullOrUndefined(this.areaDashboard.graph)">
                                            <google-chart style="width: 100%;" [type]="'ColumnChart'"
                                                          [options]="dashboardOptions" [columns]="areaDashboardColumn"
                                                          [data]="areaDashboardGraph"></google-chart>
                                            <div class="p-3">
                                                <div class="row no-wrap legend-row top justify-content-between">
                                                    <p>Nome Área</p>
                                                    <p>Total</p>
                                                </div>
                                                <ng-container *ngFor="let x of areaDashboard.dashboard">
                                                    <div class="row no-wrap legend-row align-items-center justify-content-between">
                                                        <div class="d-flex align-items-center">
                                                            <div class="square"
                                                                 [ngStyle]="{'background-color': x.color}"></div>
                                                            <p>{{x.name}}</p>
                                                        </div>
                                                        <p>{{x.count}}</p>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </ng-container>
                                        <div *ngIf="isNullOrUndefined(this.areaDashboard.graph)">
                                            <p class="text-center mb-0">{{'notFound' | translate }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>
</div>




