<a id="btnDownload" class="d-none" href="javascript:void('');" download>DownloadFakeBtn</a>

<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <h1 class="page-title">{{'gestao'|translate}}  | <span>{{'categorias'|translate}}</span></h1>
            <div class="d-flex flex-column flex-sm-row col-sm-auto px-0" style="gap:15px">
                <a href="javascript:void('');" class="btn btn-secondary" (click)="actionNew()">
                    <svg-icon src="assets/svg/plus-circle.svg"></svg-icon>
                    {{'add'|translate}}
                </a>
            </div>
        </div>
        <div class="page-filter">
            <div class="input-material">
                <input id="name" class="form-control" type="text" [(ngModel)]="filter.keyword" required/>
                <label for="name">
                    <svg-icon [svgClass]="'input-search'" src="assets/svg/search.svg"></svg-icon>
                    <span>{{'search' | translate}}</span>
                </label>
            </div>
            <a class="btn btn-primary btn-lg text-white"
               (click)="this.filter.page = 1; onFilter()">{{'buscar'|translate}}</a>
        </div>
        <!--GRID-->
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <th scope="col">{{'nome'|translate}}</th>
                    <th scope="col" class="w-10"></th>
                </tr>
                </thead>
                <tbody>
                <tr *ngIf="categoriesPage.results?.length === 0">
                    <td colspan="2">
                        <p class="text-center py-5">{{'nenhumresultado'|translate}}</p>
                    </td>
                </tr>
                <tr *ngFor="let x of categoriesPage.results |
                paginate: {itemsPerPage: categoriesPage.pageSize,
                currentPage: categoriesPage.currentPage,
                totalItems: categoriesPage.totalCount}">
                    <td>{{x.name}}</td>
                    <td class="text-right">
                        <button (click)="actionEdit(x)">
                            <svg-icon class="mr-3" src="assets/svg/edit.svg"></svg-icon>
                        </button>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="text-right">
            <pagination-controls
                    [previousLabel]="labels.previousLabel"
                    [nextLabel]="labels.nextLabel"
                    [screenReaderPaginationLabel]="labels.screenReaderPaginationLabel"
                    [screenReaderPageLabel]="labels.screenReaderPageLabel"
                    [screenReaderCurrentLabel]="labels.screenReaderCurrentLabel"
                    (pageChange)="pageChanged($event)"></pagination-controls>
        </div>
    </div>
</div>

<div class="modal-custom modal fade" id="modalUser" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content modal_content ">
            <div class="modal_header d-flex justify-content-between align-items-center">
                <h3 class="text-primary header-title">
                    {{'categoria'|translate}}
                </h3>
                <a href="javascript:void('');"
                   class="btn"
                   type="button" (click)="closeModal('modalUser')">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small" style="min-height: inherit;">
                <div class="d-block w-100">
                    <div class="row">
                        <div class="col-12">
                            <div class="input-material">
                                <input id="email" class="form-control" type="text" [(ngModel)]="model.name"
                                       required maxlength="150"/>
                                <label for="email">{{'nome'| translate}}</label>
                            </div>
                        </div>
                        <div class="col-12 my-3">
                            <button class="btn btn-primary btn-lg text-white w-100" (click)="actionSave()">
                                <span class="text-white m-0">{{'confirmar'|translate}}</span></button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
