import {AfterViewInit, Component, ViewChild, OnInit, OnDestroy} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '../../service/user.service';
import {DatePicked} from "../date-picker-custom/model/date-picked";
import {
    CheckDashboardGQL,
    CheckDashboardQuery,
    Feedback,
    FeedbackPage,
    RemoveFeedbackGQL,
    SearchFeedbackGQL,
    SearchFeedbackInput,
    SearchFeedbackQuery,
    SearchUserEvaluationInput,
    SearchUserEvaluationsGQL,
    SearchUserEvaluationsQuery,
    User,
    UserByNameGQL,
    UserEvaluationAdminDashboardGQL,
    UserEvaluationAdminDashboardPage,
    UserEvaluationAdminDashboardQuery,
    UserEvaluationDashboard,
    UserEvaluationDashboardGQL,
    UserEvaluationDashboardQuery,
    UserEvaluationPage
} from "../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import moment from "moment";

declare var $: any;

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BaseComponent implements OnInit, AfterViewInit, OnDestroy {
    user: User = new User();
    userEvaluationDashboardQuery: QueryRef<UserEvaluationDashboardQuery>;
    userEvaluationAdminDashboardQuery: QueryRef<UserEvaluationAdminDashboardQuery>;
    searchFeedbackQuery: QueryRef<SearchFeedbackQuery>;
    searchUserEvaluationsQuery: QueryRef<SearchUserEvaluationsQuery>;
    checkDashboardQuery: QueryRef<CheckDashboardQuery>;
    userEvaluationPage: UserEvaluationPage = new UserEvaluationPage();
    filter: SearchFeedbackInput = new SearchFeedbackInput();
    dashboard: UserEvaluationDashboard = new UserEvaluationDashboard();
    adminDashboard: UserEvaluationAdminDashboardPage = new UserEvaluationAdminDashboardPage();
    feedbackPage: FeedbackPage = new FeedbackPage();
    feedBack: Feedback = new Feedback();
    pageAdminDashboard = 1;
    labels: any = {
        previousLabel: ' ',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };
    btnNew = false;
    showDashboard = true;
    searchUserEvaluationInput: SearchUserEvaluationInput = new SearchUserEvaluationInput();

    constructor(public router: Router,
                private userByNameGQL: UserByNameGQL,
                private searchFeedbackGQL: SearchFeedbackGQL,
                private removeFeedbackGQL: RemoveFeedbackGQL,
                private searchUserEvaluationsGQL: SearchUserEvaluationsGQL,
                private userEvaluationDashboardGQL: UserEvaluationDashboardGQL,
                private checkDashboardGQL: CheckDashboardGQL,
                private userEvaluationAdminDashboardGQL: UserEvaluationAdminDashboardGQL,
                public userService: UserService,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngOnInit() {
        this.userService.user.subscribe((data) => {
            this.user = data as User;
            this.actionUserProfile(this.user, 'home');
        });
        this.checkDashboard();
        this.getDashBoard();
        $('#sidebar').addClass('is-hide');
        this.filter.start = moment().subtract(30, 'days').utc(true).format();
        this.filter.end = moment().utc(true).format();
    }

    ngAfterViewInit() {
        this.userService.user.subscribe({
            next: data => {
                if (!this.isNullOrUndefined(data.id)) {
                    this.btnNew = data.profile === 'local' || data.profile === 'administrator';
                }
                this.getEvaluationsUser();
                this.getFeedBack();
                if (data.profile === 'admin') {
                    this.getDashBoardAdmin();
                } else {
                    this.getDashBoard();
                }
            }
        });
    }

    ngOnDestroy(): void {
        super.destroyModal('modalFeedback');
        $('#sidebar').removeClass('is-hide');
    }

    getEvaluationsUser() {
        this.searchUserEvaluationInput.pageSize = 3;
        this.searchUserEvaluationInput.status = 'pending';
        this.searchUserEvaluationsQuery = this.searchUserEvaluationsGQL.watch({data: this.searchUserEvaluationInput});
        this.searchUserEvaluationsQuery.valueChanges.subscribe(({data}) => {
            this.userEvaluationPage = data.searchUserEvaluations as UserEvaluationPage;
        });
    }

    onFilter() {
        this.searchUserEvaluationsQuery.refetch({data: this.searchUserEvaluationInput});
    }

    pageChanged(event) {
        this.searchUserEvaluationInput.page = event;
        this.onFilter();
    }


    selectedDate(event: DatePicked) {
        this.filter.start = moment(event.start).utc(true).format();
        this.filter.end = moment(event.end).utc(true).format();
    }

    getDashBoard() {
        this.userEvaluationDashboardQuery = this.userEvaluationDashboardGQL.watch();
        this.userEvaluationDashboardQuery.valueChanges.subscribe(({data}) => {
            this.dashboard = data.userEvaluationDashboard as UserEvaluationDashboard;
        });
    }

    getDashBoardAdmin() {
        this.userEvaluationAdminDashboardQuery = this.userEvaluationAdminDashboardGQL.watch({data: this.pageAdminDashboard});
        this.userEvaluationAdminDashboardQuery.valueChanges.subscribe(({data}) => {
            this.adminDashboard = data.userEvaluationAdminDashboard as UserEvaluationAdminDashboardPage;
        });
    }

    pageChangedDashBoardAdmin(event: number) {
        this.pageAdminDashboard = event;
        this.userEvaluationAdminDashboardQuery.refetch({data: this.pageAdminDashboard})
    }

    getFeedBack() {
        this.searchFeedbackQuery = this.searchFeedbackGQL.watch({data: this.filter});
        this.searchFeedbackQuery.valueChanges.subscribe(({data}) => {
            this.feedbackPage = data.searchFeedback as FeedbackPage;
        });
    }

    onFilterFeedback() {
        this.searchFeedbackQuery.refetch({data: this.filter});
    }

    pageChangedFeedback(event: number) {
        this.filter.page = event;
        this.onFilterFeedback();
    }

    actionDelete(id: string) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('confirmedFeedbackDeleted'), () => {
            this.removeFeedbackGQL.mutate({id: id}).subscribe((result) => {
                this.searchFeedbackQuery.refetch({data: this.filter});
                super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('feedbackDeleted'), 'success');
            });
        });
    }

    actionDetail(x: Feedback) {
        this.feedBack = x;
        this.showModal('modalFeedback');
    }

    newFeedback() {
        this.feedBack = new Feedback();
        this.showModal('modalFeedback');
    }

    private checkDashboard() {
        this.checkDashboardQuery = this.checkDashboardGQL.watch();
        this.checkDashboardQuery.valueChanges.subscribe(({data}) => {
            this.showDashboard = data.checkDashboard as boolean;
        });
    }
}




