import {BrowserModule} from '@angular/platform-browser';
import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {MainComponent} from './views/main/main.component';
import {HeaderComponent} from './views/header/header.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AngularSvgIconModule} from 'angular-svg-icon';
import {SidebarComponent} from './views/sidebar/sidebar.component';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {registerLocaleData} from '@angular/common';
import localePt from '@angular/common/locales/pt';
import {LogoutComponent} from './views/logout/logout.component';
import {AppDataService} from './service/app_data.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgxPaginationModule} from 'ngx-pagination';
import {HomeComponent} from './views/home/home.component';
import {AutocompleteComponent} from './util/autocomplete/autocomplete.component';
import {NotificationComponent} from './views/notification/notification.component';
import {UserComponent} from './views/user/user.component';
import {AuthenticateComponent} from './views/authenticate/authenticate.component';
import {HeaderHttpInterceptor} from './base/header-interceptor';
import {ImageCropperModule} from 'ngx-image-cropper';
import {CropImageComponent} from './views/crop-image/crop-image.component';
// NOVAS VIEWS
import {LoginComponent} from './views/login/login.component';
import {DashboardComponent} from './views/dashboard/dashboard.component';
import {GraphQLModule} from './graphql.module';
import {CURRENCY_MASK_CONFIG} from 'ng2-currency-mask';
import {AccessControlComponent} from './views/access-control/access-control.component';
import {AccessControlHomeComponent} from './views/access-control/home/access-control-home.component';
import {CampaignComponent} from './views/campaign/campaign.component';
import {CampaignHomeComponent} from './views/campaign/home/campaign-home.component';
import {ConfigComponent} from './views/config/config.component';
import {ConfigHomeComponent} from './views/config/config-home/config-home.component';
import {NgxStarRatingModule} from 'ngx-star-rating';
import {NotificationModule} from "./views/notification/notification.module";
import {DatePickerCustomModule} from "./views/date-picker-custom/date-picker-custom.module";
import {NgxMaskModule} from "ngx-mask";
import {CampaignDetailComponent} from "./views/campaign/detail/campaign-detail.component";
import {NgMultiSelectDropDownModule} from "ng-multiselect-dropdown";
import {FeedbackModule} from "./views/feedback/feedback.module";
import {GoogleChartsModule} from 'angular-google-charts';
import { CategoriesComponent } from './views/categories/categories.component';

registerLocaleData(localePt, 'pt-BR');

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        MainComponent,
        HeaderComponent,
        SidebarComponent,
        LogoutComponent,
        HomeComponent,
        AutocompleteComponent,
        UserComponent,
        AuthenticateComponent,
        LoginComponent,
        DashboardComponent,
        AccessControlComponent,
        AccessControlHomeComponent,
        CampaignComponent,
        CampaignHomeComponent,
        ConfigComponent,
        CropImageComponent,
        ConfigHomeComponent,
        CampaignDetailComponent,
        CategoriesComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        HttpClientModule,
        NgxMaskModule.forRoot(),
        AngularSvgIconModule.forRoot(),
        SlickCarouselModule,
        FormsModule,
        GraphQLModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        NgxPaginationModule,
        NgxStarRatingModule,
        NotificationModule,
        ImageCropperModule,
        DatePickerCustomModule,
        NgMultiSelectDropDownModule,
        FeedbackModule,
        GoogleChartsModule,
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: HeaderHttpInterceptor,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useValue: 'pt-BR'
        },
        {
            provide: DEFAULT_CURRENCY_CODE,
            useValue: 'BRL'
        },
        {
            provide: CURRENCY_MASK_CONFIG,
            useValue: {   // BRL
                align: 'left',
                allowNegative: true,
                decimal: ',',
                precision: 2,
                prefix: 'R$ ',
                suffix: '',
                thousands: '.'
            }
        },
        TranslateService,
        AppDataService,
    ],
    exports: [
        FormsModule,
        AngularSvgIconModule,
        TranslateModule,
        NotificationComponent,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

export function getLocaleTag() {
    return getMeta('store-locale', 'pt-BR');
}

function getMeta(metaName, defaultValue) {
    const metas = document.getElementsByTagName('meta');

    for (let i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute('name') === metaName) {
            return metas[i].getAttribute('content');
        }
    }

    return defaultValue;
}
