import {AfterContentInit, Component, OnDestroy, OnInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {
    Country, CreateCategoryGQL, CreateEvaluationCompetenceCategoryInput,
    EvaluationCompetenceCategory,
    EvaluationCompetenceCategoryPage,
    GetUnitiesByCountryGQL,
    GetUnitiesByCountryQuery,
    RemoveUserGQL,
    SearchCategoriesGQL,
    SearchCategoriesQuery,
    SearchCategoriesQueryVariables,
    SearchEvaluationCategoryInput,
    Unity, UpdateCategoryGQL, UpdateEvaluationCompetenceCategoryInput,
    User,
} from '../../../generated/graphql';
import {QueryRef} from 'apollo-angular';
import {UserService} from '../../service/user.service';


declare var $: any;

@Component({
    selector: 'app-categories',
    templateUrl: './categories.component.html',
    styleUrls: ['./categories.component.scss']
})
export class CategoriesComponent extends BaseComponent implements AfterContentInit, OnDestroy {
    user = new User();
    categoriesPage: EvaluationCompetenceCategoryPage = new EvaluationCompetenceCategoryPage();

    countries: Array<Country>;

    leaders: Array<User>;


    // unities: Unity[] = [];
    unities: Array<Unity>;
    unityQuery: QueryRef<GetUnitiesByCountryQuery>;

    usersByProfile: Array<User>;


    model: EvaluationCompetenceCategory = new EvaluationCompetenceCategory();
    userCountry: string;

    userManegerBy: string;
    userLeadBy: string;


    isEnabledManager = true;
    isEnabledLeader = true;


    filter: SearchEvaluationCategoryInput = new SearchEvaluationCategoryInput();


    croppedImage: any = null;
    file: File = null;

    labels: any = {
        // previousLabel: '<--',
        previousLabel: ' ',
        // nextLabel: '-->',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };
    searchCategoriesQuery: QueryRef<SearchCategoriesQuery, SearchCategoriesQueryVariables>;

    constructor(public router: Router,
                public translate: TranslateService,
                private searchCategoriesGQL: SearchCategoriesGQL,
                private createCategoryGQL: CreateCategoryGQL,
                private updateCategoryGQL: UpdateCategoryGQL,
                private getUnitiesByCountryGQL: GetUnitiesByCountryGQL,
                public userService: UserService,
                private removeUserGQL: RemoveUserGQL
    ) {
        super(router, translate);
        this.filter.page = 1;
    }

    ngAfterContentInit() {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                this.actionUserProfile(this.user, 'user');
                if (!this.isNullOrUndefined(data.id)) {
                    this.getCategories();
                }
            }, error: err => super.onError(err)
        });
    }

    ngOnDestroy(): void {
        super.destroyModal('modalUser');
    }

    getCategories() {
        this.searchCategoriesQuery = this.searchCategoriesGQL.watch({data: this.filter});
        this.searchCategoriesQuery.valueChanges.subscribe(({data}) => {
            this.categoriesPage = data.searchCategories as EvaluationCompetenceCategoryPage;
        });
    }

    onFilter() {
        this.searchCategoriesQuery.refetch({data: this.filter});
    }

    actionNew() {
        this.userCountry = undefined;
        this.unities = null;
        this.userManegerBy = undefined;
        this.userLeadBy = undefined;
        this.isEnabledManager = true;
        this.isEnabledLeader = true;
        this.model = new User();
        this.showModal('modalUser');
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.model.name, text: `${this.translate.instant('nome')}<br>`},
        ];


        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCorrectFields')} <br> ${stringError}`);
            return;
        }


        if (super.isNullOrUndefined(this.model.id)) {
            this.actionCreatedUser();
        } else {
            this.actionUpdateUser();
        }
    }

    actionEdit(user: EvaluationCompetenceCategory) {
        Object.assign(this.model, user);
        this.showModal('modalUser');
    }

    actionCreatedUser() {
        const data: CreateEvaluationCompetenceCategoryInput = {
            name: this.model.name
        };
        this.createCategoryGQL.mutate({data}).subscribe((result) => {
            this.model = new EvaluationCompetenceCategory();
            this.searchCategoriesQuery.refetch({data: this.filter});
            super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningMsgCreateSuccess'), 'success');
            this.closeModal('modalUser');
        });
    }

    actionUpdateUser() {
        const data: UpdateEvaluationCompetenceCategoryInput = {
            id: this.model.id,
            name: this.model.name,
        };
        this.updateCategoryGQL.mutate({data}).subscribe((result) => {
            this.model = new EvaluationCompetenceCategory();
            this.searchCategoriesQuery.refetch({data: this.filter});
            this.closeModal('modalUser');
            super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningMsgUpdateSuccess'), 'success');
        });
    }

    actionDelete(userId) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningMsgDelete'), () => {
            this.removeUserGQL.mutate({id: userId}).subscribe((result) => {
                this.searchCategoriesQuery.refetch({data: this.filter});
                super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningMsgDeleteSuccess'), 'success');
            });
        });
    }

    pageChanged(event) {
        this.filter.page = event;
        this.onFilter();
    }

}
