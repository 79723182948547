import {AfterContentInit, Component, OnDestroy, OnInit} from '@angular/core';
import {
    Evaluation, EvaluationByTypesGQL, EvaluationByTypesQuery,
    EvaluationPage, ExtendEvaluationGQL, RemoveEvaluationGQL, SearchEvaluationInput,
    SearchEvaluationsGQL, SearchEvaluationsQuery,
    User,
} from "../../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {environment} from "../../../../environments/environment";
import {BaseComponent} from "../../../base/base.component";
import {DatePicked} from "../../date-picker-custom/model/date-picked";
import moment from "moment";
import {UserService} from "../../../service/user.service";

declare var $: any;


@Component({
    selector: 'app-config-home',
    templateUrl: './config-home.component.html',
    styleUrls: ['./config-home.component.scss']
})
export class ConfigHomeComponent extends BaseComponent implements OnInit, AfterContentInit, OnDestroy {
    evaluations: EvaluationPage = new EvaluationPage();
    evaluationsQuery: QueryRef<SearchEvaluationsQuery>;

    evaluationByTypes: any[] = [];
    evaluationByTypesQuery: QueryRef<EvaluationByTypesQuery>;

    filter = new SearchEvaluationInput();
    evaluation: Evaluation = new Evaluation();
    minDate: any = moment.utc();

    idDate: String;

    file: File = null;
    labels: any = {
        previousLabel: ' ',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

    constructor(public router: Router,
                public translate: TranslateService,
                public userService: UserService,
                private searchUserGQL: SearchEvaluationsGQL,
                private extendEvaluationGQL: ExtendEvaluationGQL,
                private removeUserGQL: RemoveEvaluationGQL,
                private evaluationByTypesGQL: EvaluationByTypesGQL,
    ) {
        super(router, translate);
        this.filter.page = 1;
    }

    ngOnInit() {
        this.userService.user.subscribe((data) => {
            this.actionUserProfile(data as User, 'config');
        }, error => super.onError(error));
        this.getEvaluationsByTypes();
    }

    ngAfterContentInit() {
        this.getEvaluations();
    }

    ngOnDestroy(): void {
        super.destroyModal('modalData');
    }

    getEvaluationsByTypes() {
        this.evaluationByTypesQuery = this.evaluationByTypesGQL.watch();
        this.evaluationByTypesQuery.valueChanges.subscribe(({data}) => {
            this.evaluationByTypes = data.evaluationByTypes;
        });
    }

    getEvaluations() {
        this.evaluationsQuery = this.searchUserGQL.watch({data: this.filter});
        this.evaluationsQuery.valueChanges.subscribe(({data}) => {
            this.evaluations = data.searchEvaluations as EvaluationPage;
            this.evaluations.results.map((x) => {
                x.type = this.getEvaluationNameByValue(x.type);
            });
        });
    }

    getEvaluationNameByValue(value: string) {
        return this.evaluationByTypes.filter(x => x.value === value)[0]?.label;
    }

    onFilter() {
        this.evaluationsQuery.refetch({data: this.filter});
    }

    pageChanged(event) {
        this.filter.page = event;
        this.onFilter();
    }

    selectedDate(event: DatePicked) {
        this.evaluation.start = moment(event.start).utc(true).format();
        this.evaluation.end = moment(event.end).utc(true).format();
    }

    getExcel() {
        const token = localStorage.getItem('token') as string;
        let url = `${environment.base_url}/users/search-user/excel?token=${token}`;
        url += ('&culture=' + this.translate.currentLang);
        if (!super.isNullOrUndefined(this.filter.keyword)) {
            url += ('&keyword=' + this.filter.keyword);
        }
        window.open(encodeURI(url));
    }

    actionDelete(id: string) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('confirmedCompanyDeleted'), () => {
            this.removeUserGQL.mutate({id: id}).subscribe((result) => {
                this.evaluationsQuery.refetch({data: this.filter});
                super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('companyDeleted'), 'success');
            });
        });
    }

    action() {
        this.extendEvaluationGQL.mutate({id: this.evaluation.id, date: this.evaluation.start}).subscribe(({data}) => {
            super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('extendedEvaluation'), 'success');
            this.closeModal('modalData');
            this.evaluationsQuery.refetch({data: this.filter});
        }, error => super.onError(error))
    }

    showModalDate(id) {
        this.evaluation.id = id;
        if (this.isNullOrUndefined(this.evaluation.start)) {
            this.evaluation.start = moment().utc(true).format()
        }
        this.showModal('modalData');
    }

    isCampaign(x: Evaluation) {
        return moment(x.start).isBefore(moment().format());
    }
}
