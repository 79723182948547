import {Component, OnInit} from '@angular/core';
import {DatePicked} from "../date-picker-custom/model/date-picked";
import {BaseComponent} from "../../base/base.component";
import {Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {
    AdhesionDashboard,
    AdhesionDashboardByUserGQL,
    AdhesionDashboardByUserQuery,
    AreaDashboard,
    AreaDashboardGQL,
    AreaDashboardQuery,
    CompetenceDashboard,
    CompetenceDashboardGQL,
    CompetenceDashboardQuery,
    Evaluation,
    EvaluationMediaDashboard,
    EvaluationMediaDashboardByUserGQL, EvaluationMediaDashboardByUserQuery,
    EvaluationsGQL,
    EvaluationsQuery,
    GetUnitiesGQL,
    GetUnitiesQuery,
    ManagerRank,
    ManagerRankByUserGQL, ManagerRankByUserQuery,
    SearchDashboardInput,
    Unity,
    UnityDashboard,
    UnityDashboardGQL,
    UnityDashboardQuery,
    User,
    UserEvaluationDashboard,
    UserEvaluationDashboardGQL,
    UserEvaluationDashboardQuery
} from "../../../generated/graphql";
import moment from "moment";
import {QueryRef} from "apollo-angular";
import {UserService} from "../../service/user.service";

declare var $: any;

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends BaseComponent implements OnInit {
    filter: SearchDashboardInput = new SearchDashboardInput();

    evaluationsQuery: QueryRef<EvaluationsQuery>;
    evaluations: Evaluation[] = [];

    managerRankQuery: QueryRef<ManagerRankByUserQuery>;
    managerRankList: ManagerRank = new ManagerRank();

    competenceDashboardQuery: QueryRef<CompetenceDashboardQuery>;
    competenceDashboard: CompetenceDashboard[] = [];

    userEvaluationDashboardQuery: QueryRef<UserEvaluationDashboardQuery>;
    userEvaluation: UserEvaluationDashboard = new UserEvaluationDashboard();

    unitiesQuery: QueryRef<GetUnitiesQuery>;
    allUnities: Unity[] = [];

    adhesionDashboardQuery: QueryRef<AdhesionDashboardByUserQuery>;
    adhesionDashboard: AdhesionDashboard[] = [];
    adhesionDashboardGraph = [["", 0, 0]];
    adhesionDashboardColumn: any[] = ['Semana', 'Pendentes', 'Finalizados'];

    evaluationMediaQuery: QueryRef<EvaluationMediaDashboardByUserQuery>;
    evaluationMedia: EvaluationMediaDashboard[] = [];
    evaluationMediaGraph = [[0, 0]];
    evaluationMediaColumn: any[] = ['Média', 'Quantidade'];

    dashboardOptions = {
        seriesType: 'bars',
        legend: {position: 'none'},
    };

    areaDashboardQuery: QueryRef<AreaDashboardQuery>;
    areaDashboard: AreaDashboard = new AreaDashboard();
    areaDashboardGraph = [["", 0]];
    areaDashboardColumn: any[] = ['Dia', 'Area'];


    unityDashboardQuery: QueryRef<UnityDashboardQuery>;
    unityDashboard: UnityDashboard = new UnityDashboard();
    unityDashboardGraph = [["", 0]];
    unityDashboardColumn: any[] = ['Dia', this.translate.instant('unidade')];

    labels: any = {
        // previousLabel: '<--',
        previousLabel: ' ',
        // nextLabel: '-->',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };
    user: User = new User();


    constructor(public router: Router,
                private evaluationsGQL: EvaluationsGQL,
                private userService: UserService,
                private getUnitiesGQL: GetUnitiesGQL,
                public unityDashboardGQL: UnityDashboardGQL,
                public areaDashboardGQL: AreaDashboardGQL,
                public managerRankGQL: ManagerRankByUserGQL,
                public adhesionDashboardGQL: AdhesionDashboardByUserGQL,
                public evaluationMediaDashboardGQL: EvaluationMediaDashboardByUserGQL,
                public competenceDashboardGQL: CompetenceDashboardGQL,
                private userEvaluationDashboardGQL: UserEvaluationDashboardGQL,
                public translate: TranslateService) {
        super(router, translate);
    }

    ngOnInit(): void {
        this.userService.user.subscribe((data) => {
            this.user = data as User;
            this.actionUserProfile(this.user, 'dashboard');
        }, error => super.onError(error));
        // this.filter.start = moment().subtract(30, 'days').utc(true).format();
        // DEFINE COMO DATA INICIAL O PRIMEIRO DIA DO ANO
        this.filter.start = moment().startOf('year').utc(true).format();
        this.filter.end = moment().utc(true).format();
        this.getEvaluations();
        this.getAllUnities();
        this.initUserEvaluation();
        this.initManagerRank();
        this.initCompetence();
        this.initGraphAdhesion();
        this.initGraphMedia();
        this.initGraphUnity();
        this.initGraphArea();
    }

    getEvaluations() {
        this.evaluationsQuery = this.evaluationsGQL.watch();
        this.evaluationsQuery.valueChanges.subscribe(({data}) => {
            this.evaluations = data.evaluations as Evaluation[];
        });
    }

    getAllUnities() {
        this.unitiesQuery = this.getUnitiesGQL.watch();
        this.unitiesQuery.valueChanges.subscribe(({data}) => {
            this.allUnities = data.unities as Unity[];
        }, error => super.onError(error));
    }


    initUserEvaluation() {
        this.userEvaluationDashboardQuery = this.userEvaluationDashboardGQL.watch();
        this.userEvaluationDashboardQuery.valueChanges.subscribe(({data}) => {
            this.userEvaluation = data.userEvaluationDashboard as UserEvaluationDashboard;
        });
    }


    initManagerRank() {
        this.managerRankQuery = this.managerRankGQL.watch({data: this.filter});
        this.managerRankQuery.valueChanges.subscribe(({data}) => {
            this.managerRankList = data.managerRankByUser as ManagerRank;
        }, error => super.onError(error));
    }


    initCompetence() {
        this.competenceDashboardQuery = this.competenceDashboardGQL.watch({data: this.filter});
        this.competenceDashboardQuery.valueChanges.subscribe(({data}) => {
            this.competenceDashboard = data.competenceDashboard as CompetenceDashboard[];
            setTimeout(() => {
                $('[data-toggle="popover"]').popover();
            }, 200);
        }, error => super.onError(error));
    }

    initGraphAdhesion() {
        this.adhesionDashboardQuery = this.adhesionDashboardGQL.watch({data: this.filter});
        this.adhesionDashboardQuery.valueChanges.subscribe(({data}) => {
            this.adhesionDashboard = data.adhesionDashboardByUser as AdhesionDashboard[];
            if (!this.isNullOrUndefined(this.adhesionDashboard)) {
                this.adhesionDashboardGraph = [];
                this.adhesionDashboard.forEach((e) => {
                    this.adhesionDashboardGraph.push([e.date, e.pending, e.done]);
                });
            }

        }, error => super.onError(error));
    }

    initGraphMedia() {
        this.evaluationMediaQuery = this.evaluationMediaDashboardGQL.watch({data: this.filter});
        this.evaluationMediaQuery.valueChanges.subscribe(({data}) => {
            this.evaluationMedia = data.evaluationMediaDashboardByUser as EvaluationMediaDashboard[];
            if (!this.isNullOrUndefined(this.evaluationMedia)) {
                this.evaluationMediaGraph = [];
                this.evaluationMedia.forEach((e) => {
                    this.evaluationMediaGraph.push([e.average, e.count]);
                });
            }

        }, error => super.onError(error))
    }

    initGraphUnity() {
        this.unityDashboardQuery = this.unityDashboardGQL.watch({data: this.filter});
        this.unityDashboardQuery.valueChanges.subscribe(({data}) => {
            this.unityDashboard = data.unityDashboard as UnityDashboard;
            this.unityDashboardColumn = ['Dia'];

            if (!this.isNullOrUndefined(this.unityDashboard.graph)) {
                this.unityDashboard.graph[0].detail.forEach((e) => {
                    this.unityDashboardColumn.push(e.name, {role: 'style'});
                });
                this.unityDashboardGraph = [];
                this.unityDashboard.graph.forEach((graph) => {
                    let ArrayGraph: any[] = [graph.month.toString() + '/' + graph.year.toString()];
                    graph.detail.forEach((e) => {
                        ArrayGraph.push(e.count, e.color);
                    });
                    this.unityDashboardGraph.push(ArrayGraph);
                });
            }
        }, error => super.onError(error));
    }

    initGraphArea() {
        this.areaDashboardQuery = this.areaDashboardGQL.watch({data: this.filter});
        this.areaDashboardQuery.valueChanges.subscribe(({data}) => {
            this.areaDashboard = data.areaDashboard as AreaDashboard;
            this.areaDashboardColumn = ['Dia'];
            if (!this.isNullOrUndefined(this.areaDashboard.graph)) {
                this.areaDashboard.graph[0].detail.forEach((e) => {
                    this.areaDashboardColumn.push(e.name, {role: 'style'});
                });
                this.areaDashboardGraph = [];
                this.areaDashboard.graph.forEach((graph) => {
                    let ArrayGraph: any[] = [graph.month.toString() + '/' + graph.year.toString()];
                    graph.detail.forEach((e) => {
                        ArrayGraph.push(e.count, e.color);
                    });
                    this.areaDashboardGraph.push(ArrayGraph);
                });
            }

        }, error => super.onError(error));
    }

    selectedDate(event: DatePicked) {
        this.filter.start = moment(event.start).utc(true).format();
        this.filter.end = moment(event.end).utc(true).format();
    }

    onFilter() {
        this.managerRankQuery.refetch({data: this.filter}).then();
        this.competenceDashboardQuery.refetch({data: this.filter}).then();
        this.adhesionDashboardQuery.refetch({data: this.filter}).then();
        this.evaluationMediaQuery.refetch({data: this.filter}).then();
        this.unityDashboardQuery.refetch({data: this.filter}).then();
        this.areaDashboardQuery.refetch({data: this.filter}).then();
    }

    pageChanged($event: number) {
        this.filter.page = $event;
        this.onFilter();
    }
}
