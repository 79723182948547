import {environment} from '../../environments/environment';
import {Router} from '@angular/router';
import Swal from 'sweetalert2';
import {TranslateService} from '@ngx-translate/core';
import moment from 'moment';
import {User, UserEvaluationCompetence} from "../../generated/graphql";
import {PermissionsPage} from "../models/permissions";

declare var $: any;

export class BaseComponent {
    arrStatus = ['pending', 'doing', 'done'];
    permission: PermissionsPage = new PermissionsPage();

    constructor(public router: Router,
                public translate: TranslateService) {
    }

    setToken(token: string) {
        localStorage.setItem('token', token);
    }

    getBaseURL() {
        return environment.base_url;
    }

    setCulture(culture: string) {
        localStorage.setItem('culture', culture);
    }

    setLanguage(l: string) {
        localStorage.setItem('language', l);
    }

    getLanguage() {
        return localStorage.getItem('language');
    }

    handlerCultureLanguage(language) {
        switch (language) {
            case 'en':
                this.setCulture('en-US');
                this.setLanguage('en');
                break;
            case 'es':
                this.setCulture('es-ES');
                this.setLanguage('es');
                break;
            default :
                this.setCulture('pt-BR');
                this.setLanguage('pt');
        }
    }

    switchLanguage(language: string, canReload = false) {
        if (language === 'pt') {
            language = 'pt';
            this.setCulture('pt-BR');
        } else if (language === 'en') {
            language = 'en';
            this.setCulture('en-US');
        } else if (language === 'es') {
            language = 'es';
            this.setCulture('es-ES');
        }
        this.translate.use(language);
        this.handlerCultureLanguage(language);
        if (canReload) {
            location.reload();
        }
    }

    actionUserProfile(user: User, permissionItem?: string) {
        switch (user.profile) {
            case 'admin':
                this.permission = {
                    home: true,
                    avaliation: true,
                    config: true,
                    user: true,
                    newFeedback: true,
                    dashboard: true,
                };
                break;
            case 'manager':
                this.permission = {
                    home: false,
                    avaliation: true,
                    config: false,
                    user: false,
                    newFeedback: false,
                    dashboard: true,
                };
                break;
            case 'leader':
                this.permission = {
                    home: false,
                    avaliation: true,
                    config: false,
                    user: false,
                    newFeedback: false,
                    dashboard: true,
                };
                break;
            case 'director':
                this.permission = {
                    home: false,
                    avaliation: true,
                    config: false,
                    user: false,
                    newFeedback: false,
                    dashboard: true,
                };
                break;
            case 'user':
                this.permission = {
                    home: false,
                    avaliation: true,
                    config: false,
                    user: false,
                    newFeedback: false,
                    dashboard: false,
                };
                break;

        }
        if (!this.isNullOrUndefined(this.permission[permissionItem]) && !this.permission[permissionItem]) {
            this.router.navigate(['/avaliation']);
        }
    }

    handlerConfirm() {
        $('#modalNoteResume').modal('show');
    }

    showModal(idModal) {
        $('#' + idModal).modal('show');
    }

    closeModal(idModal) {
        $('#' + idModal).modal('hide');
    }

    showMessage(t: string, m: string, type: any = 'warning') {
        Swal.fire({
            title: t,
            html: m,
            icon: type,
            confirmButtonColor: '#032E58',
        });
    }

    confirmMessage(t: string, m: string, callback: any = null) {
        Swal.fire({
            title: t,
            html: m,
            icon: 'warning',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            confirmButtonColor: '#032E58',
            cancelButtonColor: '#dc3545'
        }).then((result) => {
            if (!this.isNullOrUndefined(result.value) && result.value === true) {
                if (callback != null) {
                    callback();
                }
            }
        });
    }

    onError(error) {
        this.toggleLoader(false);

        if (typeof (error) === 'string') {
            error = JSON.parse(error);
        }

        if (error.status === 401 || error.statusCode === 401) {
            this.router.navigate(['/authenticate']);
            return;
        }

        // const e = JSON.parse(error['error']);

        const m = this.isNullOrUndefined(error.message) ? error.error.message : error.message;

        if (error.status === 400 || error.statusCode === 400) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), m, 'warning');
            return;
        }

        if (error.status === 500 || error.statusCode === 500) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningMsgError'), 'warning');
            return;
        }
    }

    isNullOrUndefined(value) {
        return typeof value === 'undefined' || value == null || value.length === 0;
    }

    validateField(obj) {
        let strError = ``;
        obj.map((e) => {
            if (this.isNullOrUndefined(e.value)) {
                strError += `${e.text}`;
            }
        });
        return strError;
    }

    handlerFlagImage(value) {
        switch (value) {
            case 'Chile':
                return 'chile.svg';
            case 'Peru':
                return 'peru.svg';
            case 'Bolívia':
                return 'bolivia.svg';
            case 'Venezuela':
                return 'venezuela.svg';
            case 'Argentina':
                return 'argentina.svg';
            case 'México':
                return 'mexico.svg';
            case 'Equador':
                return 'equador.svg';
            case 'Guatemala':
                return 'guatemala.svg';
            case 'Brazil':
                return 'brazil.svg';
            case 'Colômbia':
                return 'colombia.svg';
            case 'Uruguai':
                return 'uruguay.svg';
            case 'Paraguay':
                return 'paraguay.svg';
        }
    }

    setStatus() {
        let i = 'cancel';
        switch (i) {
            case 'cancel':
                return 'pending';
            case 'finished':
                return 'finished';
            case 'pending':
                return 'notfinished';
        }
    }

    handlerStepBar(value) {

        switch (value) {
            case 1:
                return 'width: 0%';
            case 2:
                return 'width: 25%';
            case 3:
                return 'width: 50%';
            case 4:
                return 'width: 75%';
            case 5:
                return 'width: 100%';
        }
    }

    handlerTitlesBoxVertical(): void {
        const el = $('.page-content__details__tab');
        if (!el[0]) {
            return;
        }
        el.each((i, x) => {
            $(x).css({width: $(x).parent()[0].getBoundingClientRect().height});
        });
    }

    handlerBoxSize(): void {
        const el = $('.js-set-border');
        if (!el[0]) {
            return;
        }
        el.each((i, x) => {
            const size = $(x).parent()[0].getBoundingClientRect().height;
            $(x)
                .css({
                    height: size
                })
                .find('span').css({
                width: size
            });
        });
    }

    getMonthNames() {
        let arrMonth = [];
        switch (this.translate.currentLang) {
            case 'en':
                arrMonth = [
                    'January',
                    'February',
                    'March',
                    'April',
                    'May',
                    'June',
                    'July',
                    'August',
                    'September',
                    'October',
                    'November',
                    'December'
                ];
                break;
            case 'es':
                arrMonth = [
                    'Enero',
                    'Febrero',
                    'Marzo',
                    'Abril',
                    'Mayo',
                    'Junio',
                    'Julio',
                    'Agosto',
                    'Septiembre',
                    'Octubre',
                    'Noviembre',
                    'Deciembre'
                ];
                break;
            default:
                arrMonth = [
                    'Janeiro',
                    'Fevereiro',
                    'Março',
                    'Abril',
                    'Maio',
                    'Junho',
                    'Julho',
                    'Agosto',
                    'Setembro',
                    'Outubro',
                    'Novembro',
                    'Dezembro'
                ];
                break;
        }
        return arrMonth;
    }

    getDaysOfDayWeek() {
        let arr = [];
        switch (this.translate.currentLang) {
            case 'en':
                arr = [
                    'Sun',
                    'Mon',
                    'Tue',
                    'Wed',
                    'Thu',
                    'Fri',
                    'Sat'
                ];
                break;
            case 'es':
                arr = [
                    'Dom',
                    'lun',
                    'mar',
                    'mie',
                    'jue',
                    'vie',
                    'Sab'
                ];
                break;
            default:
                arr = [
                    'Dom',
                    'Seg',
                    'Ter',
                    'Qua',
                    'Qui',
                    'Sex',
                    'Sab'
                ];
                break;
        }
        return arr;
    }

    destroyModal(idModal) {
        const elModalUser = $('#' + idModal);
        if (elModalUser[0]) {
            elModalUser.remove();
        }
        /*   const backdrop = $('.modal-backdrop.show');
           if (backdrop[0]) {
               backdrop.remove();
           }
           $('body').removeClass('modal-open');*/
    }

    setDatePicker(id: string, callback, date = 30): void {
        $('.' + id).daterangepicker({
            startDate: moment().subtract(date, 'days'),
            cancelButtonClasses: 'btn-danger',
            ranges: {
                Hoje: [moment(), moment()],
                Ontem: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                '7 Dias': [moment().subtract(6, 'days'), moment()],
                '30 Dias': [moment().subtract(29, 'days'), moment()]
            },
            locale: {
                customRangeLabel: 'Personalizar',
                format: 'DD/MM/YYYY',
                separator: ' - ',
                applyLabel: 'Aplicar',
                cancelLabel: 'Cancelar',
                monthNames: this.getMonthNames(),
                daysOfWeek: this.getDaysOfDayWeek(),
                firstDay: 1
            }
        }, callback);
    }

    setDate(id: string, callback, startDate?, timepicker?, maxDate?, format = 'DD/MM/YYYY') {
        $('.' + id).daterangepicker({
            timePicker: timepicker ? true : false,
            timePicker24Hour: timepicker ? true : false,
            startDate: startDate ? moment(startDate).utc() : moment(),
            singleDatePicker: true,
            maxDate: maxDate,
            cancelButtonClasses: 'btn-danger',
            locale: {
                customRangeLabel: 'Personalizar',
                format: format,
                separator: ' - ',
                applyLabel: 'Aplicar',
                cancelLabel: 'Cancelar',
                monthNames: this.getMonthNames(),
                daysOfWeek: this.getDaysOfDayWeek(),
                firstDay: 1
            }
        }, callback);
    }

    toggleLoader(b: boolean): void {
        if (!b) {
            $('#loaderBox').fadeOut();
        } else {
            $('#loaderBox').fadeIn();
        }
    }

    isEMailValid(strEmail) {
        const str = strEmail;
        const exp = /^([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})$/i;
        const testResult = exp.test(str);
        if (!testResult) {
            this.showMessage(this.translate.instant('baseComponent.warning'), this.translate.instant('baseComponent.warningMsgEmail'), 'warning');
        }
        return testResult;
    }

    isCPFValid(strCPF) {
        let Soma;
        let Resto;
        Soma = 0;
        const strErrorTitle = this.translate.instant('attention');
        const strErrorDescription = 'Informe um CPF válido para prosseguir.';
        strCPF = strCPF.replace(/\D+/g, '');
        if (strCPF === '00000000000' || strCPF === '12112112155') {
            this.showMessage(strErrorTitle, strErrorDescription, 'warning');
            return false;
        }
        for (let i = 1; i <= 9; i++) {
            // tslint:disable-next-line:radix
            Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);
        }
        Resto = (Soma * 10) % 11;
        if ((Resto === 10) || (Resto === 11)) {
            Resto = 0;
        }
        // tslint:disable-next-line:radix
        if (Resto !== parseInt(strCPF.substring(9, 10))) {
            this.showMessage(strErrorTitle, strErrorDescription, 'warning');
            return false;
        }
        Soma = 0;
        for (let i = 1; i <= 10; i++) {
            // tslint:disable-next-line:radix
            Soma = Soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);
        }
        Resto = (Soma * 10) % 11;
        if ((Resto === 10) || (Resto === 11)) {
            Resto = 0;
        }
        // tslint:disable-next-line:radix
        if (Resto !== parseInt(strCPF.substring(10, 11))) {
            this.showMessage(strErrorTitle, strErrorDescription, 'warning');
            return false;
        }
        return true;
    }

    onLogout() {
        this.confirmMessage(this.translate.instant('baseComponent.warning'), 'Deseja realmente sair?', () => {
            const p = window.location.pathname.split('/');
            const virtualPath = p.length >= 3 ? '/' + p[1] : '';
            const redirectUrl = 'https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=' +
                window.location.origin + virtualPath + '/logout';
            window.location.href = redirectUrl;
        });
    }

    getTranslate(s: string) {
        if (!this.isNullOrUndefined(s)) {
            return this.translate.instant(s);
        }
    }

    getMangerTypeByKey(k: string) {
        if (!this.isNullOrUndefined(k)) {
            return this.translate.instant('managersName.' + k);
        }
    }

    objLocaleDateRangePicker() {
        return {
            customRangeLabel: 'Personalizar',
            format: 'DD/MM/YYYY',
            separator: ' - ',
            applyLabel: 'Aplicar',
            cancelLabel: 'Cancelar', // btn-outline-primary
            daysOfWeek: [
                'Dom',
                'Seg',
                'Ter',
                'Qua',
                'Qui',
                'Sex',
                'Sab'
            ],
            monthNames: [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro'
            ],
            firstDay: 1
        };
    }

    getPdi(competence: UserEvaluationCompetence[]) {
        let count = 0;
        if (!this.isNullOrUndefined(competence)) {
            competence.forEach((e) => {
                count += e.pdi.length;
            })
        }
        return count;
    }
}
