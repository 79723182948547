import {Component, OnDestroy, OnInit, AfterContentInit} from '@angular/core';
import {BaseComponent} from '../../base/base.component';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Observable, throwError} from 'rxjs';
import moment from 'moment';
import {
    User,
    Country,
    GetCountriesGQL,
    GetCountriesQuery,
    // Unity,
    // GetUnitiesQuery,
    // GetUnitiesGQL,
    SearchUserGQL,
    SearchUserQuery,
    CreateUserGQL,
    UpdateUserGQL,
    RemoveUserGQL,
    CreateUserInput,
    SearchUserInput,
    UpdateUserInput,
    UserPage,
    Unity,
    GetUnitiesQuery,
    GetUnitiesByCountryGQL,
    GetUnitiesByCountryQuery,
    UsersByProfileQuery,
    UsersByProfileGQL,
    GetUnitiesGQL,
    CreateUserManagerInput,
    LeadersByManagerGQL, LeadersByManagerQuery, DirectorsGQL, DirectorsByLeadersGQL, DirectorsByLeadersQuery
} from '../../../generated/graphql';

import {QueryRef} from 'apollo-angular';
import {map, throwIfEmpty} from 'rxjs/operators';
import {environment} from '../../../environments/environment';
import {UserService} from '../../service/user.service';
import {ExcelService} from '../../service/excel.service';


declare var $: any;

@Component({
    selector: 'app-user',
    templateUrl: './user.component.html',
    styleUrls: ['./user.component.scss']
})
export class UserComponent extends BaseComponent implements AfterContentInit, OnDestroy {
    user = new User();
    users: UserPage = new UserPage();
    usersQuery: QueryRef<SearchUserQuery>;

    countries: Array<Country>;
    countryQuery: QueryRef<GetCountriesQuery>;

    usersManager: Array<User>;
    usersManagerQuery: QueryRef<UsersByProfileQuery>;

    usersLeader: Array<User>;
    leaders: Array<User>;
    // usersLeaderQuery: QueryRef<UsersByProfileQuery>;
    usersLeaderQuery: QueryRef<LeadersByManagerQuery>;

    // unities: Unity[] = [];
    unities: Array<Unity>;
    unityQuery: QueryRef<GetUnitiesByCountryQuery>;

    usersByProfile: Array<User>;
    usersByProfileQuery: QueryRef<UsersByProfileQuery>;

    usersDirectors: Array<User>;
    usersDirectorsByLead: Array<User>;
    usersDirectorsByLeadQuery: QueryRef<DirectorsByLeadersQuery>;

    allUnities: Observable<Array<Unity>>;
    unitiesQuery: QueryRef<GetUnitiesQuery>;

    model: User = new User();
    userCountry: string;
    userUnity: string;
    userCreatedBy: string;
    userManegerBy: string;
    userLeadBy: string;
    userDirectorBy: string;

    isEnabledManager = true;
    isEnabledLeader = true;

    validateProfileCountry: boolean;

    filter = new SearchUserInput();

    // keyword = '';
    // countrySelected: string = null;
    // profileSelected: string = null;
    // unitySelected: string = null;


    thumpPath: string | ArrayBuffer;
    croppedImage: any = null;
    file: File = null;

    modelUserPreUnity = undefined;
    modelUserPreCountry = undefined;

    labels: any = {
        // previousLabel: '<--',
        previousLabel: ' ',
        // nextLabel: '-->',
        nextLabel: ' ',
        screenReaderPaginationLabel: 'Pagination',
        screenReaderPageLabel: 'page',
        screenReaderCurrentLabel: `You're on page`
    };

    constructor(public router: Router,
                public translate: TranslateService,
                private searchUserGQL: SearchUserGQL,
                private getCountriesGQL: GetCountriesGQL,
                private createUserGQL: CreateUserGQL,
                private updateUserGQL: UpdateUserGQL,
                private usersByProfileGQL: UsersByProfileGQL,
                private directorsByLeadersGQL: DirectorsByLeadersGQL,
                private directorsGQL: DirectorsGQL,
                private leadersByManagerGQL: LeadersByManagerGQL,
                private getUnitiesByCountryGQL: GetUnitiesByCountryGQL,
                private getUsersByProfileGQL: UsersByProfileGQL,
                private getUnitiesGQL: GetUnitiesGQL,
                public userService: UserService,
                public excelService: ExcelService,
                private removeUserGQL: RemoveUserGQL
    ) {
        super(router, translate);
        this.filter.page = 1;
    }

    ngAfterContentInit() {
        this.userService.user.subscribe({
            next: data => {
                this.user = data as User;
                this.actionUserProfile(this.user, 'user');
                if (!this.isNullOrUndefined(data.id)) {
                    this.getUsers();
                    this.getCountries();
                    this.getUsersManagers();
                    this.getUserByProfile();
                    this.getUsersLeaders();
                    this.getUsersDirectors();
                }
                /*if (!this.isNullOrUndefined(data.country)) {
                    this.validateProfileCountry = this.actionValidateProfile();
                    if (this.validateProfileCountry) {
                        this.filter.country = this.user.country?.id;
                    }
                    this.getCountries();
                    // this.getUnities();
                    this.getUsers();
                }*/
            }, error: err => super.onError(err)
        });


        this.setDatePicker('picker-period', (start, end, label) => {
            // this.modelFilter.start = start.utcOffset(0, true).format();
            // this.modelFilter.end = end.utcOffset(0, true).format();
        });

        this.getAllUnities();
    }

    ngOnDestroy(): void {
        super.destroyModal('modalUser');
        // const elModalUser = $('#modalUser');
        // if (elModalUser[0]) {
        //     elModalUser.remove();
        // }
    }

    getUsers() {
        this.usersQuery = this.searchUserGQL.watch({data: this.filter});
        // this.users = this.usersQuery.valueChanges.pipe(map(result => result.data.searchUsersAll as Array<User>));
        this.usersQuery.valueChanges.subscribe(({data}) => {
            this.users = data.searchUser as UserPage;
        });
    }

    getCountries() {
        this.countryQuery = this.getCountriesGQL.watch();
        this.countryQuery.valueChanges.subscribe(({data}) => {
            this.countries = data.countries as Array<Country>;
        });
    }

    getUsersManagers() {
        this.usersManagerQuery = this.usersByProfileGQL.watch({data: 'manager'});
        this.usersManagerQuery.valueChanges.subscribe(({data}) => {
            this.usersManager = data.usersByProfile as Array<User>;
        });
    }

    getUsersDirectors() {
        this.directorsGQL.watch().valueChanges.subscribe(({data}) => {
            this.usersDirectors = data.directors as Array<User>;
        });
    }

    getUsersLeaders() {
        this.usersLeaderQuery = this.leadersByManagerGQL.watch({profile: 'leader', id: this.filter.managerId});
        this.usersLeaderQuery.valueChanges.subscribe(({data}) => {
            this.usersLeader = data.leaderByManager as Array<User>;
        });
    }

    getLeaders() {
        this.usersLeaderQuery = this.leadersByManagerGQL.watch({profile: 'leader', id: this.userManegerBy});
        this.usersLeaderQuery.valueChanges.subscribe(({data}) => {
            this.leaders = data.leaderByManager as Array<User>;
        });
    }

    getDirectorById() {
        this.usersDirectorsByLeadQuery = this.directorsByLeadersGQL.watch({id: this.userLeadBy});
        this.usersDirectorsByLeadQuery.valueChanges.subscribe(({data}) => {
            this.usersDirectorsByLead = data.directorsByLeaders as Array<User>;
        });
    }

    setListLeaders() {
        switch (this.user?.profile) {
            case 'manager':
                return this.usersLeader;
            default:
                return this.leaders;
        }
    }

    setManagerLeader($event: any) {
        if (this.isNullOrUndefined($event)) {
            this.isEnabledManager = true;
            this.userManegerBy = undefined;
            this.isEnabledLeader = true;
            this.userLeadBy = undefined;
        }
        switch ($event) {
            case 'manager':
                this.isEnabledManager = false;
                this.userManegerBy = undefined;
                this.isEnabledLeader = false;
                this.userLeadBy = undefined;
                break;
            case 'user':
                this.isEnabledManager = false;
                this.userManegerBy = undefined;
                this.isEnabledLeader = false;
                this.userLeadBy = undefined;
                break;
            case 'admin':
                this.isEnabledManager = false;
                this.userManegerBy = undefined;
                this.isEnabledLeader = false;
                this.userLeadBy = undefined;
                break;
            case 'leader':
                this.isEnabledManager = false;
                this.userManegerBy = undefined;
                this.isEnabledLeader = true;
                this.userLeadBy = undefined;
                break;
        }
    }

    getUnitiesByCountry($event: any) {
        if (this.isNullOrUndefined($event)) {
            this.unities = [];
            return false;
        }
        this.unityQuery = this.getUnitiesByCountryGQL.watch({input: $event});
        this.unityQuery.valueChanges.subscribe(({data}) => {
            this.unities = data.getUnitiesByCountry as Array<Unity>;
        });
    }

    getUserByProfile() {
        this.usersByProfileQuery = this.getUsersByProfileGQL.watch({data: 'manager'});
        this.usersByProfileQuery.valueChanges.subscribe(({data}) => {
            this.usersByProfile = data.usersByProfile as Array<User>;
        });
    }

    getAllUnities() {
        this.unitiesQuery = this.getUnitiesGQL.watch();
        this.allUnities = this.unitiesQuery.valueChanges.pipe(map(result => result.data.unities as Array<Unity>));
    }

    onFilter() {
        this.usersQuery.refetch({data: this.filter});
    }

    actionNew() {
        this.userCountry = undefined;
        this.unities = null;
        this.userManegerBy = undefined;
        this.userLeadBy = undefined;
        this.isEnabledManager = true;
        this.isEnabledLeader = true;
        this.model = new User();
        this.showModal('modalUser');
    }

    actionSave() {
        const arrValidateFields = [
            {value: this.model.name, text: `${this.translate.instant('nome')}<br>`},
            {value: this.model.re, text: 'RE<br>'},
            {value: this.model.costCenter, text: this.translate.instant('centrocusto') + '<br>'},
            {value: this.model.role, text: this.translate.instant('cargo') + '<br>'},
            {value: this.model.area, text: this.translate.instant('area') + '<br>'},
            {value: this.model.directorate, text: this.translate.instant('avaliationHistoryComponent.directors') + '<br>'},
            // {value: this.userCreatedBy, text: 'Gestor Imediato<br>'},
            // {value: this.userManegerBy, text: 'Gestor Imediato<br>'},
            // {value: this.userLeadBy, text: 'Líder<br>'},
            {value: this.model.admissionDate, text: this.translate.instant('dataAdminissao') + '<br>'},
            {value: this.userCountry, text: this.translate.instant('country') + '<br>'},
            {value: this.userUnity, text: `${this.translate.instant('unidade')}<br>`},
            // {value: this.model.unity.name, text: 'Unidade<br>'},
            {value: this.model.profile, text: this.translate.instant('perfil') + '<br>'},
        ];

        switch (this.model.profile) {
            case 'user':
                arrValidateFields.push(
                    {value: this.userDirectorBy, text: 'Diretor<br>'},
                    {value: this.userManegerBy, text: 'Gestor Imediato<br>'},
                    // {value: this.userLeadBy, text: 'Líder<br>'},
                );
                break;
            case 'admin':
                // arrValidateFields.push(
                //     {value: this.userManegerBy, text: 'Gestor Imediato<br>'},
                //     {value: this.userLeadBy, text: 'Líder<br>'},
                // )
                break;
            case 'leader':
                arrValidateFields.push(
                    {value: this.userDirectorBy, text: 'Diretor<br>'},
                    {value: this.userManegerBy, text: 'Gestor Imediato<br>'},
                );
                break;
        }

        if (this.model.profile !== 'user') {
            arrValidateFields.push(
                // {value: this.model.phone, text: 'Telefone<br>'},
                {value: this.model.officeEmail, text: 'E-mail Eurofarma<br>'},
                {value: this.model.email, text: 'E-mail<br>'},);
        }

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warningAttention'), `${this.translate.instant('baseComponent.warningCorrectFields')} <br> ${stringError}`);
            return;
        }

        // if ((!this.isEMailValid(this.model.email) || !this.isEMailValid(this.model.officeEmail)) && this.model.profile !== 'user') {
        //     return;
        // }

        this.model.unity = this.unities.filter(x => x.id === this.userUnity)[0];

        if (super.isNullOrUndefined(this.model.id)) {
            this.actionCreatedUser();
        } else {
            this.actionUpdateUser();
        }
    }

    actionEdit(user: User) {
        Object.assign(this.model, user);
        this.model.admissionDate = moment(this.model.admissionDate).utc(false).format('DD-MM-YYYY');
        this.userCountry = this.model.unity?.country?.id;
        this.userUnity = this.model.unity?.id;
        // this.userCreatedBy = this.model.createdBy?.id;
        this.userManegerBy = this.model.managerBy?.id;
        this.userLeadBy = this.model.leadBy?.id;
        this.getUnitiesByCountry(this.userCountry);
        this.getLeaders();

        switch (user.profile) {
            case 'manager':
                this.isEnabledManager = false;
                this.isEnabledLeader = false;
                break;
            case 'user':
                this.isEnabledManager = false;
                this.isEnabledLeader = false;
                break;
            case 'admin':
                this.isEnabledManager = false;
                this.isEnabledLeader = false;
                break;
            case 'leader':
                this.isEnabledManager = false;
                this.isEnabledLeader = true;
                break;
        }

        // this.isEnabledManager = false;
        // this.isEnabledLeader = false;
        // this.unityQuery.refetch({input: this.userCountry});
        this.showModal('modalUser');
    }

    actionCreatedUser() {
        const data = new CreateUserInput();
        // data.createdBy = new User();
        data.unity = new Unity();
        data.name = this.model.name;
        data.officeEmail = this.model.officeEmail;
        data.re = this.model.re;
        data.costCenter = this.model.costCenter;
        data.role = this.model.role;
        data.area = this.model.area;
        data.directorate = this.model.directorate;
        //  data.createdBy.id = this.userCreatedBy;
        // data.managerBy = this.userManegerBy
        // data.leadBy = this.userLeadBy;
        data.directorBy = {
            id: this.userDirectorBy
        };
        data.managerBy = {
            id: this.userManegerBy
        };
        data.leadBy = {
            id: this.userLeadBy
        };
        data.admissionDate = moment(this.model.admissionDate, 'DD/MM/YYYY').utc(false).format();
        data.unity.id = this.model.unity.id;
        data.phone = this.model.phone;
        data.name = this.model.name;
        data.email = this.model.email;
        data.profile = this.model.profile;
        data.status = this.model.status === undefined || !this.model.status ? 'inactive' : 'active';
        this.createUserGQL.mutate({input: data}).subscribe((result) => {
            this.model = new User();
            if (!super.isNullOrUndefined(this.file)) {
                this.uploadImage(result.data.createUser.id, this.file);
            } else {
                this.usersQuery.refetch({data: this.filter});
                this.closeModal('modalUser');
            }
        });
        super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningMsgCreateSuccess'), 'success');
    }

    actionUpdateUser() {
        const data = new UpdateUserInput();
        data.id = this.model.id;
        // data.createdBy = new User();
        data.unity = new Unity();
        data.name = this.model.name;
        data.officeEmail = this.model.officeEmail;
        data.re = this.model.re;
        data.costCenter = this.model.costCenter;
        data.role = this.model.role;
        data.area = this.model.area;
        data.directorate = this.model.directorate;
        //  data.createdBy.id = this.userCreatedBy;
        data.directorBy = {
            id: this.userDirectorBy
        };
        data.managerBy = {
            id: this.userManegerBy
        };
        data.leadBy = {
            id: this.isNullOrUndefined(this.userLeadBy) ? null : this.userLeadBy
        };
        data.admissionDate = moment(this.model.admissionDate, 'DD/MM/YYYY').utc(false).format();
        data.unity.id = this.model.unity.id;
        data.phone = this.model.phone;
        data.name = this.model.name;
        data.email = this.model.email;
        data.profile = this.model.profile;
        // data.status = this.model.status === undefined || !this.model.status ? 'inactive' : 'active';
        data.status = this.model.status;
        this.updateUserGQL.mutate({input: data}).subscribe((result) => {
            this.model = new User();
            if (!super.isNullOrUndefined(this.file)) {
                this.uploadImage(result.data.updateUser.id, this.file);
            } else {
                this.usersQuery.refetch({data: this.filter});
                this.closeModal('modalUser');
            }
        });
        super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningMsgUpdateSuccess'), 'success');
    }

    uploadImage(id: string, file: File) {
        this.userService.upload(file, id).subscribe(
            data => {
                this.usersQuery.refetch({data: this.filter});
                this.closeModal('modalUser');
            }, error => super.onError(error));
    }

    actionDelete(userId) {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('baseComponent.warningMsgDelete'), () => {
            this.removeUserGQL.mutate({id: userId}).subscribe((result) => {
                this.usersQuery.refetch({data: this.filter});
                super.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningMsgDeleteSuccess'), 'success');
            });
        });
    }

    pageChanged(event) {
        this.filter.page = event;
        this.onFilter();
    }

    getExcel() {
        const token = localStorage.getItem('token') as string;
        console.log(environment.base_url);
        let url = `${environment.base_url}/users/search-user/excel?token=${token}`;
        url += ('&culture=' + this.translate.currentLang);
        if (!super.isNullOrUndefined(this.filter.keyword)) {
            url += ('&keyword=' + this.filter.keyword);
        }
        if (!super.isNullOrUndefined(this.filter.profile)) {
            url += ('&profile=' + this.filter.profile);
        }
        window.open(encodeURI(url));
    }

    actionExcel() {
        this.excelService.getExcelUser(this.filter).subscribe({
            next: data => {
                this.downloadHandlerFile(data);
            }, error: err => super.onError(err)
        });
    }

    downloadHandlerFile(e) {
        $('#btnDownload').attr('href', e);
        setTimeout(() => {
            $('#btnDownload')[0].click();
        });
    }

    actionUserProfileFilter() {
        switch (this.user?.profile) {
            case 'medical-manager':
                return false;
            case 'district-manager':
                return false;
            case 'analyst-marketing':
                return true;
            case 'product-manager':
                return true;
            case 'marketing-manager':
                return true;
            case 'admin':
                return true;
            case 'director':
                return true;
            case 'manager':
                return false;
            case 'leader':
                return false;
            case 'consult':
                return false;
        }
    }

    actionValidateProfile() {
        switch (this.user?.profile) {
            case 'medical-manager':
                return false;
            case 'district-manager':
                return false;
            case 'admin':
                return false;
            case 'consult':
                return false;
            default:
                return true;
        }
    }

    onClearModelUser(event: FocusEvent, str: string) {
        setTimeout(() => {
            const a = $(event.target).val();
            if (super.isNullOrUndefined(a)) {
                this.model[str] = '';
            }
        });
    }

    setCroppedImage(o: any) {
        this.croppedImage = o.thumb;
        this.handleUpload(o.file);
    }

    handleUpload(event) {
        const files = event;
        if (files.length > 0) {
            if (files[0].size > 2000000) {
                super.showMessage(this.translate.instant('profile.edit.warningAttention'),
                    this.translate.instant('profile.edit.warningUpload'), 'info');
                return;
            }
            if (files[0].type.toLowerCase().indexOf('jpeg') === -1
                && files[0].type.toLowerCase().indexOf('jpg') === -1
                && files[0].type.toLowerCase().indexOf('png') === -1) {
                super.showMessage(this.translate.instant('profile.edit.warningUploadExtensions'), this.translate.instant('profile.edit.warningAttention'),
                    'info');
                return;
            }
        }
        const reader = new FileReader();
        reader.onload = (_event) => {
            this.thumpPath = _event.target.result;
        };
        reader.readAsDataURL(event[0]);
        this.file = files[0];
        // this.uploadImage();
    }

    uploadExcel(event) {
        const files = event.target.files;
        if (files.length > 0) {
            if (files[0].size > 2000000) {
                super.showMessage(this.translate.instant('profile.edit.warningAttention'),
                    this.translate.instant('profile.edit.warningUpload'), 'info');
                return;
            }
            if (files[0].type.toLowerCase().indexOf('xlt') === -1
                && files[0].type.toLowerCase().indexOf('xls') === -1
                && files[0].type.toLowerCase().indexOf('xlsx') === -1
                && files[0].type.toLowerCase().indexOf('sheet') === -1) {
                super.showMessage(this.translate.instant('profile.edit.warningUploadExtensions'), this.translate.instant('profile.edit.warningAttention'),
                    'info');
                return;
            }
        }

        const reader = new FileReader();
        console.log(files[0]);
        this.excelService.uploadExcel(files[0]).subscribe((data) => {
            this.usersQuery.refetch({data: this.filter});
            // this.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('baseComponent.warningMsgDeleteSuccess'), 'success');
            this.showMessage(this.translate.instant('baseComponent.success'), `Importação realizada com sucesso!`, 'success');

        }, err => super.onError(err));
        reader.readAsDataURL(event.target.files[0]);
        $('#doc-1').val('');


    }

    changeStatus(event: Event) {
        if (event) {
            this.model.status = 'active';
        } else {
            this.model.status = 'inactive';
        }
    }
}
