import {Injectable} from '@angular/core';
import {ServiceBase} from '../base/service.base';
import {HttpClient} from '@angular/common/http';
import {Observable} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ExcelService extends ServiceBase {

    constructor(http: HttpClient) {
        super(http);
    }

    getExcelUser(model) {
        const url = `${this.BASE_URL}/users/search-user/excel`;
        return this.http.post(url, model);
    }

    getExcelUserEvaluation(model) {
        const url = `${this.BASE_URL}/user-evaluation/search/excel`;
        return this.http.post(url, model);
    }

    uploadExcel(file: File) {
        const url = `${this.BASE_URL}/users/excel-import`;
        return new Observable((observer) => {
            const formData: FormData = new FormData(),
                xhr: XMLHttpRequest = new XMLHttpRequest();
            if (file != null) {
                formData.append('file', file, file.name);
            }
            xhr.onreadystatechange = () => {
                if (xhr.readyState === 4) {
                    if (xhr.status === 200) {
                        observer.next(JSON.parse(xhr.response));
                        observer.complete();
                    } else {
                        observer.error(xhr.response);
                    }
                }
            };
            xhr.upload.onprogress = (event) => {
                const progress = Math.round(event.loaded / event.total * 100);
            };
            xhr.open('POST', url, true);
            xhr.setRequestHeader('Authorization', 'Bearer ' + this.getToken());
            xhr.send(formData);
        });
        // return this.http.post(url, model);
    }

}
