<nav id="sidebar">
    <ul class="list-unstyled components navbar-nav" id="accordionExample">

        <li class="header__mobile d-lg-none">
            <div class="header">
                <!--                <div class="d-flex justify-content-around text-white">-->
                <!--                    <font class="header_font text-white">Idioma:</font>-->
                <!--                    <a class="header__link" [ngClass]="{'active' : translate.currentLang === 'pt'}"-->
                <!--                       href="javascript:void('');"-->
                <!--                       >PT</a>-->
                <!--                    <span class="separator">|</span>-->
                <!--                    <a class="header__link" [ngClass]="{'active' : translate.currentLang === 'es'}"-->
                <!--                       href="javascript:void('');"-->
                <!--                       (click)="switchLanguage('es')">ES</a>-->
                <!--                </div>-->
                <div class="header__btn mobile_notification notification_trigger">
                    <app-notification></app-notification>
                </div>
            </div>
        </li>

        <li class="nav-item  d-lg-none">
            <a href="javascript:void(0);" [routerLink]="['/']" class="nav-link text-white sidebar_top"
               style="height: initial;"
               (click)="actionCloseCollapse()">
                <span class="ico"><svg-icon src=""></svg-icon></span>
                <span class="text-center">
                  <!--<span class="lbl sidebar-title">
                      BIO
                  </span>-->
                  <span class="d-block d-lg-none header__user mb-0">
                    <span>{{'headerSalutation' | translate}}, <strong>{{user.name}}</strong></span>
                     <img src="{{!user?.photo ? 'assets/images/user.png' : user?.photo}}"
                          class="profile_photo"/>
                  </span>
                </span>
            </a>
        </li>

        <li class="nav-item" *ngIf="permission.home">
            <a href="javascript:void('Nota');" [routerLink]="['/']" class="nav-link align-items-center"
               style="gap: 20px"
               [ngClass]="{'active': getRoute() === '/'}"
               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/home.svg"></svg-icon>
                <span class="lbl">Home</span>
            </a>
        </li>
        <li class="nav-item" *ngIf="permission.dashboard">
            <a href="javascript:void('Nota');" [routerLink]="['/dashboard']" class="nav-link align-items-center"
               style="gap: 20px"
               [ngClass]="{'active': getRoute() === '/dashboard'}"
               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/dashboard.svg"></svg-icon>
                <span class="lbl">Dashboard</span>
            </a>
        </li>
        <li class="nav-item" *ngIf="permission.avaliation">
            <a href="javascript:void('Nota');" [routerLink]="['/avaliation']"
               [ngClass]="{'active': getRoute() === '/avaliation'}"
               class="nav-link text-white align-items-center"
               style="gap: 20px"
               (click)="actionCloseCollapse()">
                <svg-icon src="assets/svg/avaliation.svg"></svg-icon>
                <span class="lbl">{{'avaliacoes'|translate}}</span>
            </a>
        </li>

        <li class="nav-item" *ngIf="permission.user || permission.config">
            <a href="javascript:void(0);"
               [ngClass]="{'active':router.url === '/user' || router.url === '/config' || getRoute() === '/campaign'}"
               class="nav-link text-white align-items-center" style="gap: 20px"
               data-toggle="collapse"
               data-target="#idea" aria-expanded="false"
               aria-controls="idea">
                <svg-icon src="assets/svg/gear.svg"></svg-icon>
                <span class="lbl">{{'gestao'|translate}}</span>
            </a>
            <div class="collapse" id="idea" data-parent="#accordionExample">
                <a href="javascript:void(0);" *ngIf="permission.user"
                   (click)="actionCloseCollapse()" [routerLink]="['/user']"
                   [ngClass]="{'active':router.url === '/user'}"
                   class="nav-link text-white align-items-center">
                    <span class="lbl">{{'usuarios'|translate}}</span>
                </a>
                <a href="javascript:void(0);" *ngIf="permission.config"
                   (click)="actionCloseCollapse()" [routerLink]="['/config']"
                   [ngClass]="{'active': getRoute() === '/config'|| getRoute() === '/campaign'}"
                   class="nav-link text-white align-items-center">
                    <span class="lbl">{{'configavali'|translate}}</span>
                </a>
                <a href="javascript:void(0);" *ngIf="permission.config"
                   (click)="actionCloseCollapse()" [routerLink]="['/categories']"
                   [ngClass]="{'active': getRoute() === '/categories'|| getRoute() === '/categories'}"
                   class="nav-link text-white align-items-center">
                    <span class="lbl">{{'categorias'|translate}}</span>
                </a>
            </div>
        </li>
        <!--<li class="nav-item">
            <a href="javascript:void(0);" class="nav-link text-white align-items-center" style="gap: 20px"
               data-toggle="collapse"
               data-target="#setting" aria-expanded="false"
               aria-controls="setting">
                <svg-icon src="assets/svg/settings.svg"></svg-icon>
                <span class="lbl">Configurações</span>
            </a>
            <div class="collapse" id="setting" data-parent="#accordionExample">
                <a href="javascript:void(0);"
                   (click)="actionCloseCollapse()" [routerLink]="['/settings/camera']"
                   class="nav-link text-white align-items-center">
                    <span class="lbl">Câmera</span>
                </a>
                <a href="javascript:void(0);" (click)="actionCloseCollapse()" [routerLink]="['/']"
                   class="nav-link text-white align-items-center">
                    <span class="lbl">Acesso</span>
                </a>
                <a href="javascript:void(0);" (click)="actionCloseCollapse()" [routerLink]="['/']"
                   class="nav-link text-white align-items-center">
                    <span class="lbl">Alarme</span>
                </a>
                <a href="javascript:void(0);" (click)="actionCloseCollapse()" [routerLink]="['/']"
                   class="nav-link text-white align-items-center">
                    <span class="lbl">Bloco</span>
                </a>
                <a href="javascript:void(0);" (click)="actionCloseCollapse()" [routerLink]="['/']"
                   class="nav-link text-white align-items-center">
                    <span class="lbl">Unidade</span>
                </a>
            </div>
        </li>-->
    </ul>
    <div class="logout d-lg-none">
        <a href="javascript:void('sair');" [routerLink]="['/logout']">
            {{'sair' | translate}}
        </a>
    </div>

</nav>
