export class PermissionsPage {
    home: boolean;
    avaliation: boolean;
    config: boolean;
    user: boolean;
    newFeedback: boolean;
    dashboard: boolean;


    constructor() {
    }
}
