import {Component, OnInit, OnDestroy} from '@angular/core';
import {BaseComponent} from "../../../base/base.component";
import {ActivatedRoute, Router} from "@angular/router";
import {TranslateService} from "@ngx-translate/core";
import {
    CompetenceCategoriesGQL,
    CreateEvaluationCompetenceInput,
    CreateEvaluationGQL,
    CreateEvaluationInput,
    Evaluation, EvaluationByTypesGQL, EvaluationByTypesQuery,
    EvaluationCompetence, EvaluationCompetenceCategory,
    EvaluationGQL,
    EvaluationQuery,
    FindLastEvaluationGQL,
    FindLastEvaluationQuery, QtdWithout, RefreshEvaluationUsersGQL, RestartUserEvaluationGQL,
    UpdateEvaluationGQL,
    UpdateEvaluationInput,
    User,
    UserQtdWithoutEvaluationGQL, UserQtdWithoutEvaluationQuery
} from "../../../../generated/graphql";
import {QueryRef} from "apollo-angular";
import {DatePicked} from "../../date-picker-custom/model/date-picked";
import moment from "moment";
import {UserService} from "../../../service/user.service";

@Component({
    selector: 'app-campaign-detail',
    templateUrl: './campaign-detail.component.html',
    styleUrls: ['./campaign-detail.component.scss']
})
export class CampaignDetailComponent extends BaseComponent implements OnInit, OnDestroy {
    parmId: string;
    solicitationQuery: QueryRef<EvaluationQuery>;
    findLastEvaluationQuery: QueryRef<FindLastEvaluationQuery>;
    evaluation: Evaluation = new Evaluation();
    loggedUser: User = new User();
    modalCompetence: EvaluationCompetence = new EvaluationCompetence();
    competences: EvaluationCompetence[] = [];
    handlerIndex: number;
    createCompetence: boolean;
    confirmationEmailMessage: any;

    evaluationByTypes: any[] = [];
    evaluationByTypesQuery: QueryRef<EvaluationByTypesQuery>;

    enableSave: boolean;

    QtdWithoutQuery: QueryRef<UserQtdWithoutEvaluationQuery>;
    qtdWithout: QtdWithout = new QtdWithout();

    competenceCategories: EvaluationCompetenceCategory[] = [];

    constructor(public router: Router,
                private evaluationGQL: EvaluationGQL,
                private updateEvaluationGQL: UpdateEvaluationGQL,
                private createEvaluationGQL: CreateEvaluationGQL,
                private findLastEvaluationGQL: FindLastEvaluationGQL,
                private router2: ActivatedRoute,
                private userService: UserService,
                public translate: TranslateService,
                private QtdWithoutGQL: UserQtdWithoutEvaluationGQL,
                private updateUsersEvaluationGQL: RefreshEvaluationUsersGQL,
                private evaluationByTypesGQL: EvaluationByTypesGQL,
                private competenceCategoriesGQL: CompetenceCategoriesGQL
    ) {
        super(router, translate);
    }

    ngOnDestroy(): void {
        super.destroyModal('modalCompetence');
        super.destroyModal('emailModal');
    }

    ngOnInit(): void {
        this.getEvaluationsByTypes();
        this.getCategories();
        this.enableSave = false;
        this.router2.params.subscribe(url => {
                if (!this.isNullOrUndefined(url.id)) {
                    this.parmId = url.id;
                    this.solicitationQuery = this.evaluationGQL.watch({id: url.id});
                    this.solicitationQuery.valueChanges.subscribe(({data}) => {
                        this.evaluation = JSON.parse(JSON.stringify(data.evaluation)) as Evaluation;
                        this.competences = this.evaluation.competences;
                        this.userService.user.subscribe((data) => {
                            this.loggedUser = data as User;
                        });
                    });
                    this.getQtdWithoutEvaluation();
                } else {
                    this.findLastEvaluationQuery = this.findLastEvaluationGQL.watch();
                    this.findLastEvaluationQuery.valueChanges.subscribe(({data}) => {
                        this.competences = JSON.parse(JSON.stringify(data.findLastEvaluation)) as EvaluationCompetence[];
                        this.enableSave = this.competences.length > 0;
                    });
                }
            }
        );
    }

    getEvaluationsByTypes() {
        this.evaluationByTypesQuery = this.evaluationByTypesGQL.watch();
        this.evaluationByTypesQuery.valueChanges.subscribe(({data}) => {
            this.evaluationByTypes = data.evaluationByTypes;
        });
    }

    getCategories() {
        this.competenceCategoriesGQL.watch().valueChanges.subscribe(({data}) => {
            this.competenceCategories = data.competenceCategories;
        });
    }

    detailCompetence(c: EvaluationCompetence, ind: number) {
        this.handlerIndex = ind;
        this.createCompetence = false;
        this.modalCompetence = Object.assign({}, c);
        this.showModal('modalCompetence');
    }

    newCompetence() {
        this.createCompetence = true;
        this.modalCompetence = new EvaluationCompetence();
        this.showModal('modalCompetence');
    }

    removeCompetence(ind: number) {
        this.enableSave = true;
        if (ind > -1) {
            this.competences.splice(ind, 1);
        }
    }

    onSave() {
        const arrValidateFields = [
            {value: this.evaluation.name, text: this.translate.instant('nome') + '<br>'},
            {value: this.evaluation.type, text: 'Tipo<br>'},
            {value: this.competences, text: this.translate.instant('competencia') + '<br>'},
        ];

        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warning'), `${this.translate.instant('FillFieldsCorrectly')} <br> ${stringError}`);
            return;
        }
        if (this.isNullOrUndefined(this.evaluation.start)) {
            this.evaluation.start = moment().utc(true).format();
        }
        if (this.isNullOrUndefined(this.evaluation.end)) {
            this.evaluation.end = moment().utc(true).format();
        }

        if (super.isNullOrUndefined(this.evaluation.id)) {
            this.actionCreate();
        } else {
            this.actionUpdate();
        }
    }

    handlerCompetence() {
        let competenceInput: CreateEvaluationCompetenceInput[] = [];
        this.competences.forEach((e) => {
            return competenceInput.push({
                name: e.name,
                competenceEvaluation: e.competenceEvaluation,
                description: e.description,
                informative: e.informative,
                categoryId: e.categoryId,
            });
        });
        return competenceInput;
    }

    actionCreate() {
        const data: CreateEvaluationInput = {
            confirmationEmailMessage: this.evaluation.confirmationEmailMessage,
            name: this.evaluation.name,
            start: this.evaluation.start,
            end: this.evaluation.end,
            type: this.evaluation.type,
            competence: this.handlerCompetence(),
        };
        this.createEvaluationGQL.mutate({input: data}).subscribe(() => {
            this.modalCompetence = new EvaluationCompetence();
            this.router.navigate(['/config']);
            this.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('companyCreated'), 'success');
        }, error => super.onError(error));
    }

    actionUpdate() {
        let data: UpdateEvaluationInput = {
            id: this.evaluation.id,
            confirmationEmailMessage: this.evaluation.confirmationEmailMessage,
            name: this.evaluation.name,
            start: this.evaluation.start,
            end: this.evaluation.end,
            competence: this.handlerCompetence(),
            status: this.evaluation.status
        };
        if (this.evaluation.status != 'pending') {
            super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('confirmedCompanyUpdate'), () => {
                this.updateEvaluationGQL.mutate({input: data}).subscribe(() => {
                    this.modalCompetence = new EvaluationCompetence();
                    this.router.navigate(['/config']);
                    this.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('companyUpdate'), 'success');
                }, error => super.onError(error));
            });
        } else {
            super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('confirmedCompanyUpdate2'), () => {
                this.updateEvaluationGQL.mutate({input: data}).subscribe(() => {
                    this.modalCompetence = new EvaluationCompetence();
                    this.router.navigate(['/config']);
                    this.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('companyUpdate'), 'success');
                }, error => super.onError(error));

            });
        }

    }

    onSaveCompetence() {
        this.enableSave = true;
        const arrValidateFields = [
            {value: this.modalCompetence.name, text: this.translate.instant('titulocompetencia') + '<br>'},
        ];


        const stringError = this.validateField(arrValidateFields);

        if (!super.isNullOrUndefined(stringError)) {
            this.showMessage(this.translate.instant('baseComponent.warning'), `${this.translate.instant('FillFieldsCorrectly')} <br> ${stringError}`);
            return;
        }

        if (this.createCompetence) {
            this.actionCreateCompetence();
        } else {
            this.actionUpdateCompetence();
        }
    }

    actionCreateCompetence() {
        this.competences.push(this.modalCompetence);
        this.closeModal('modalCompetence');
    }

    actionUpdateCompetence() {
        if (!this.isNullOrUndefined(this.handlerIndex)) {
            this.competences[this.handlerIndex] = this.modalCompetence;
            this.closeModal('modalCompetence');
        }
    }

    selectedDate(event: DatePicked) {
        this.evaluation.start = moment(event.start).utc(true).format();
        this.evaluation.end = moment(event.end).utc(true).format();
    }

    cancelEmail() {
        this.confirmationEmailMessage = null;
        this.closeModal('emailModal');
    }

    addMessage() {
        this.enableSave = true;
        this.evaluation.confirmationEmailMessage = this.confirmationEmailMessage;
        this.closeModal('emailModal');
    }

    newMessage() {
        this.confirmationEmailMessage = this.evaluation.confirmationEmailMessage;
        this.showModal('emailModal');
    }

    removeMessage() {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('confirmEmailMessageDeleted'), () => {
            this.evaluation.confirmationEmailMessage = null;
            this.enableSave = true;
        });
    }

    getQtdWithoutEvaluation() {
        this.QtdWithoutQuery = this.QtdWithoutGQL.watch({id: this.parmId});

        this.QtdWithoutQuery.valueChanges.subscribe(({data}) => {
            this.qtdWithout = data.userQtdWithoutEvaluation as QtdWithout;
            console.log(this.qtdWithout);
        });
    }

    ActionUpdateUsersEvaluation() {
        super.confirmMessage(this.translate.instant('baseComponent.warningAttention'), this.translate.instant('confirmUpdateUsersEvaluation'), () => {
            this.updateUsersEvaluationGQL.mutate({type: this.evaluation.type, id: this.parmId}).subscribe(({data}) => {
                this.router.navigate(['/config']);
                this.showMessage(this.translate.instant('baseComponent.success'), this.translate.instant('updateUsersEvaluation'), 'success');
            }, error => super.onError(error));
        });
    }
}
