<div class="page">
    <div class="page-padding">
        <div class="page-header-custom">
            <h1 class="page-title">Campanha<!-- | <span>Gestão</span>--></h1>
            <!--<a href="javascript:void('');" class="btn btn-gray-fill">
                <svg-icon class="right" src="assets/svg/downloads.svg"></svg-icon>
                Improtar Excel
            </a>-->

        </div>
        <div class="page-filter">
            <div class="input-material">
                <input id="name" class="form-control" type="text" required/>
                <label for="name">
                    <svg-icon [svgClass]="'input-search'" src="assets/svg/search.svg"></svg-icon>
                    <span>Busca</span>
                </label>
            </div>
            <div class="input-material">
                <select id="campaign" class="form-control" name="type">
                    <option selected
                            [ngValue]="undefined">{{'select' | translate}}</option>
                </select>
                <label for="campaign" class="mb-0 mr-3">Campanha</label>
            </div>
            <div class="input-material">
                <input id="date" class="form-control" type="text" required/>
                <label for="date">
                    <span>Período</span>
                    <svg-icon class="right" src="assets/svg/calendar.svg"></svg-icon>
                </label>
            </div>
            <div class="input-material select">
                <select id="status" class="form-control" name="type">
                    <option selected>{{'select' | translate}}</option>
                </select>
                <label for="status" class="mb-0 mr-3 disabled"> Status</label>
            </div>
            <a class="btn btn-primary btn-lg text-white">Buscar</a>
        </div>

        <!--GRID-->
        <div class="table-responsive">
            <table class="table">
                <thead>
                <tr>
                    <!--                <th scope="col">ID</th>-->
                    <th scope="col">RE</th>
                    <th scope="col">Colaborador</th>
                    <th scope="col">Campanha</th>
                    <th scope="col" class="text-center">Inicio</th>
                    <th scope="col" class="text-center">Conclusão</th>
                    <th scope="col" class="text-center">Status</th>
                    <th class="text-center" scope="col">Feedback</th>
                    <th class="text-center" scope="col">Avaliar</th>
                    <th class="text-center" scope="col">Histórico</th>

                </tr>
                </thead>
                <tbody>

                <tr>
                    <td>0000</td>
                    <td>Carlos Sampaio</td>
                    <td>Aval. Competencia 2022</td>
                    <td class="text-center">9/02/2022</td>
                    <td class="text-center">25/02/2022</td>
                    <td class="status_col pending">
                        <span>
                            Pendente
                        </span>
                    </td>
                    <td class="text-center">
                        <svg-icon class="feedback_svg active" src="assets/svg/feedback.svg"></svg-icon>
                    </td>
                    <td class="text-center">
                        <svg-icon src="assets/svg/arquive.svg" [routerLink]="['/campaign/create']"></svg-icon>
                    </td>
                    <td class="text-center">
                        <svg-icon src="assets/svg/eye.svg"></svg-icon>
                    </td>
                </tr>
                <tr>
                    <td>0000</td>
                    <td>Carlos Sampaio</td>
                    <td>Aval. Competencia 2022</td>
                    <td class="text-center">9/02/2022</td>
                    <td class="text-center">25/02/2022</td>
                    <td class="status_col finished">
                        <span>
                            {{'finalizada'|translate}}
                        </span>
                    </td>
                    <td class="text-center">
                        <svg-icon class="feedback_svg active" src="assets/svg/feedback.svg"></svg-icon>
                    </td>
                    <td class="text-center">
                        <svg-icon src="assets/svg/arquive.svg" [routerLink]="['/campaign/create']"></svg-icon>
                    </td>
                    <td class="text-center">
                        <svg-icon src="assets/svg/eye.svg"></svg-icon>
                    </td>
                </tr>
                <tr>
                    <td>0000</td>
                    <td>Carlos Sampaio</td>
                    <td>Aval. Competencia 2022</td>
                    <td class="text-center">9/02/2022</td>
                    <td class="text-center">25/02/2022</td>
                    <td class="status_col notfinished">
                        <span>
                            {{'naofinalizada'|translate}}
                        </span>
                    </td>
                    <td class="text-center">
                        <svg-icon class="feedback_svg active" src="assets/svg/feedback.svg"></svg-icon>
                    </td>
                    <td class="text-center">
                        <svg-icon src="assets/svg/arquive.svg" [routerLink]="['/campaign/create']"></svg-icon>
                    </td>
                    <td class="text-center">
                        <svg-icon src="assets/svg/eye.svg"></svg-icon>
                    </td>
                </tr>
                <tr *ngFor="let i of [1,2,3,4]">
                    <td>0000</td>
                    <td>Carlos Sampaio</td>
                    <td>Aval. Competencia 2022</td>
                    <td class="text-center">9/02/2022</td>
                    <td class="text-center">25/02/2022</td>
                    <td class="status_col pending">
                        <span>
                            Pendente
                        </span>
                    </td>
                    <td class="text-center">
                        <svg-icon class="feedback_svg" src="assets/svg/feedback.svg"></svg-icon>
                    </td>
                    <td class="text-center">
                        <svg-icon src="assets/svg/arquive.svg" [routerLink]="['/campaign/create']"></svg-icon>
                    </td>
                    <td class="text-center">
                        <svg-icon src="assets/svg/eye.svg"></svg-icon>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

    </div>
</div>




