<div class="page">
    <div class="page-padding">
        <div class="page-header-custom justify-content-between">
            <div class="d-flex align-items-end">
                <svg-icon class="mr-3" src="assets/svg/back.svg" [routerLink]="['/config']"></svg-icon>
                <h1 class="page-title">{{this.isNullOrUndefined(parmId) ? ('novacampanha'|translate) : ('campanha'|translate)}}</h1>

            </div>
            <div class="user-info" *ngIf="!this.isNullOrUndefined(evaluation.createdBy)">
                <p>{{'criadopor'|translate}} <b>{{evaluation.createdBy?.name}}</b> <br>
                    em {{evaluation.createdAt| date:'dd/MM/yyyy'}}</p>
                <img src="assets/images/usermock.png" alt="">
            </div>
            <div *ngIf="loggedUser.profile === 'admin' && qtdWithout.qtd > 0" class="col-md-2">
                <a (click)="ActionUpdateUsersEvaluation()"
                   class="w-10 btn btn-block btn-outline-primary">{{'restart2'|translate}} {{qtdWithout.qtd}} {{'colab'|translate}}</a>
            </div>
        </div>
        <div>
            <div class="campaign">
                <div class="row campaign-top">
                    <div class="col-md-4">
                        <div class="desc">
                            <h3 class="pdi">{{'campanha'|translate}}</h3>
                            <p class="campaign_text">
                                {{'configurarcampanhasimples'|translate}}
                            </p>
                        </div>
                    </div>
                    <div class="col-md-4 create">
                        <div class="input-material w-100">
                            <input id="campaign" [(ngModel)]="evaluation.name"
                                   [disabled]="!this.isNullOrUndefined(parmId)"
                                   class="form-control" type="text"
                                   required/>
                            <label for="campaign" class="disabled">
                                <span>{{'nomecampanha'|translate}}</span>
                            </label>
                        </div>
                        <app-date-picker [disabled]="!this.isNullOrUndefined(parmId)" [start]="evaluation.start"
                                         [end]="evaluation.end"
                                         (selectDate)="selectedDate($event)" class="w-100"></app-date-picker>
                    </div>
                    <div class="col-md-4 create">
                        <div class="input-material w-100">
                            <select id="evaluationType" [(ngModel)]="evaluation.type" class="form-control" required>
                                <option [ngValue]="undefined">Selecione</option>
                                <option *ngFor="let x of evaluationByTypes" [ngValue]="x.value">{{x.label}}</option>
                            </select>
                            <label for="evaluationType">
                                <span>Tipo</span>
                            </label>
                        </div>
                        <div class="input-material w-100" *ngIf="!this.isNullOrUndefined(parmId)">
                            <select id="status" class="form-control" name="type" [(ngModel)]="evaluation.status">
                                <option selected [ngValue]="undefined">{{'select' | translate}}</option>
                                <option selected [ngValue]="'pending'">{{'pending' | translate}}</option>
                                <option selected [ngValue]="'active'">{{'active' | translate}}</option>
                                <option selected [ngValue]="'finished'">{{'finished' | translate}}</option>
                            </select>
                            <label for="status" class="mb-0 mr-3">  {{'status'}}</label>
                        </div>
                    </div>
                </div>
                <div class="desc">
                    <div class="desc-top">
                        <h3 class="pdi">{{'competencia'|translate}}</h3>
                        <ng-container *ngIf="evaluation.status != 'finished'">
                            <a href="javascript:void('');" class="btn_secondary btn btn-secondary"
                               (click)="newCompetence()">
                                <svg-icon class="mr-2" src="assets/svg/plus-circle.svg"></svg-icon>
                                {{'add'|translate}}
                            </a>
                        </ng-container>
                    </div>
                    <p class="campaign_text mb-4">
                        {{'agoravamos'|translate}}<b>{{'competencia'|translate}}</b>,
                        {{'campaignDetailComponent.confirm' |translate}}
                        <b>{{'add'|translate}}</b>.
                    </p>
                </div>
                <div class="competence-card" *ngFor="let c of competences;let i = index">
                    <div class="row no-gutters">
                        <div class="col-lg-1">
                            <div class="marker" style="height: 100%">
                                <div class="number">{{i + 1}}</div>
                            </div>
                        </div>
                        <div class="col-lg">
                            <div class="content">
                                <div class="row align-items-center">
                                    <div class="col">
                                        <h4 class="pd1">{{c.name}}</h4>
                                    </div>
                                    <div *ngIf="evaluation.status != 'finished'" class="col-auto d-flex" style="gap: 15px">
                                        <a (click)="detailCompetence(c,i)">
                                            <svg-icon src="assets/svg/edit.svg"></svg-icon>
                                        </a>
                                        <a (click)="removeCompetence(i)">
                                            <svg-icon src="assets/svg/close-circle.svg"></svg-icon>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="competence-card" *ngIf="!this.isNullOrUndefined(this.evaluation.confirmationEmailMessage)">
                    <div class="row align-items-center content" style="column-gap: 30px">
                        <div class="col">
                            <p class="competence_text mb-0">
                                {{this.evaluation.confirmationEmailMessage}}
                            </p>
                        </div>
                        <div class="col-lg-auto d-flex justify-content-end" style="gap: 15px">

                            <ng-container>
                                <a (click)="newMessage()" *ngIf="evaluation.status != 'finished'">
                                    <svg-icon src="assets/svg/edit.svg">
                                    </svg-icon>
                                </a>
                                <a (click)="removeMessage()" *ngIf="evaluation.status != 'finished'">
                                    <svg-icon src="assets/svg/close-circle.svg"></svg-icon>
                                </a>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="row justify-content-between" *ngIf="evaluation.status != 'finished'">
                    <div class="col-6 col-md-4">
                        <a [routerLink]="['/config']"
                           class="btn btn-sm btn-block btn-outline-primary">{{'cancel'|translate}}</a>
                    </div>
                    <div class="col-6 col-md-4">
                        <button class="btn btn-sm btn-block btn-primary text-white" [disabled]="!enableSave"
                                (click)="onSave()">{{'save'|translate}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-custom modal fade" id="modalCompetence" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <p class="modal-header-title">{{'novacompetencia'|translate}}</p>
                <a (click)="closeModal('modalCompetence')" class="btn" type="button">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div class="row">
                    <div class="col-12">
                        <p class="sub">{{'camposobrigatorios'|translate}}</p>
                    </div>
                    <div class="col-12">
                        <div class="input-material">
                            <input id="date" [(ngModel)]="modalCompetence.name" class="form-control" type="text"
                                   required/>
                            <label for="date">
                                <span>{{'titulocompetencia'|translate}}</span>
                            </label>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="input-material">
                            <select id="competenceCategories" [(ngModel)]="modalCompetence.categoryId" class="form-control" required>
                                <option [ngValue]="undefined">Selecione</option>
                                <option *ngFor="let x of competenceCategories" [ngValue]="x.id">{{x.name}}</option>
                            </select>
                            <label for="competenceCategories">
                                <span>{{'categoria'|translate}}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <a class="btn btn-outline-primary btn-lg w-100"
                           (click)="closeModal('modalCompetence')">{{'cancel'|translate}}</a>
                    </div>
                    <div class="col-6">
                        <a (click)="onSaveCompetence()"
                           class="btn btn-primary btn-lg text-white w-100">{{'save'|translate}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal-custom modal fade" id="emailModal" data-keyboard="false"
     data-backdrop="static" role="dialog"
     aria-labelledby="modalNoteResumeLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-md" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <p class="modal-header-title">{{'emailMessage'|translate}}</p>
                <a (click)="closeModal('emailModal')" class="btn" type="button">
                    <svg-icon src="assets/svg/close.svg"></svg-icon>
                </a>
            </div>
            <div class="modal-body modal-body--small">
                <div class="row">
                    <div class="col-md-12">
                        <div class="input-material">
                              <textarea [(ngModel)]="confirmationEmailMessage"
                                        [disabled]="evaluation.status == 'finished'"
                                        id="confirmationEmailMessage" required
                                        maxlength="1000"
                                        class="form-control"></textarea>
                            <label for="confirmationEmailMessage"></label>
                        </div>
                    </div>
                </div>
                <div class="row" *ngIf="evaluation.status != 'finished'">
                    <div class="col-6">
                        <a class="btn btn-outline-primary btn-lg w-100"
                           (click)="cancelEmail()">{{'cancel'|translate}}</a>
                    </div>
                    <div class="col-6">
                        <a (click)="addMessage()"
                           class="btn btn-primary btn-lg text-white w-100">{{'save'|translate}}</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

